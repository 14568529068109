import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";

const RentalListComponent = () => {
  const [rentalList, setRentalList] = useState([]);
  const [loggedUserPhoneNumber, setLoggedUserPhoneNumber] = useState(null);

  const getRentalListRef = collection(db, "Rentals");
  const navigate = useNavigate();

  useEffect(() => {
    getRentalList();
  }, []);

  const getRentalList = async () => {
    const loggedUserInfo = localStorage.getItem('loggedInUser');
    let mobNo = null;
    if(JSON.parse(loggedUserInfo)){
      setLoggedUserPhoneNumber(JSON.parse(loggedUserInfo)?.mobileNo)
      mobNo = JSON.parse(loggedUserInfo)?.mobileNo;
    }else{
      navigate(`/login?navigate=rental-list`);
      return;
    }

    const data = await getDocs(getRentalListRef);
    const tempRentalList = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    console.log("listCheck",tempRentalList)
    setRentalList(tempRentalList.filter((obj)=>obj?.phone?.toString().indexOf(mobNo?.toString()) !== -1));
  };

  const onEditClick = (id) => {
    navigate(`/rental/${id}`);
  };

  return (
    <div>
      <section className="page p-0 " id="contact">
        <div className="container-fluid">
          <div className="top-0 w-100">
            <div className="row">
              <div className="col-lg-8 col-xl-9 order-1 order-lg-0 pt-6 pt-lg-0">
                <section className="pt-8 pt-xl-7 pt-xxl-8 pb-0">
                  <div
                    className="bg-holder bg-size"
                    style={{
                      backgroundImage: "url(assets/img/contact-bg.png)",
                      backgroundPosition: "left center",
                      backgroundSize: "auto",
                    }}
                  ></div>

                  <div className="container-fluid">
                    <div className="row justify-content-center pb-5">
                      <div className="col-sm-9 col-lg-10">
                        <div className="text-center mb-3 mb-lg-3">
                          <h2>
                            <span className="fw-medium">Rental List</span>
                          </h2>
                        </div>
                        <div>
                          <TableContainer
                            component={Paper}
                            style={{ height: "450px", overflowY: "auto" }}
                          >
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell align="right">Phone No</TableCell>
                                  <TableCell align="right">
                                    Product Id
                                  </TableCell>
                                  <TableCell align="right">Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rentalList &&
                                  rentalList.map((row) => (
                                    <TableRow
                                      key={row.name}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {row.name}
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.phone}
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.uniqueId}
                                      </TableCell>
                                      <TableCell align="right">
                                        <Button
                                          onClick={() => {
                                            onEditClick(row?.id);
                                          }}
                                          style={{ marginLeft: "5px" }}
                                          variant="outlined"
                                        >
                                          Edit
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-4 col-xl-3 col-12 position-absolute position-lg-relative ps-lg-0">
                <div
                  className="sticky-top py-4 sticky-area"
                  data-sidebar-close="sidebar-close"
                >
                  <div className="btn-close-boots-container times">
                    <a href="/home">
                      <div className="btn-close-boots"></div>
                    </a>
                  </div>
                  <div
                    className="bg-holder sidebar-rounded"
                    style={{
                      backgroundImage: "url(/assets/img/sidebars/contact.png)",
                    }}
                  ></div>

                  <div className="position-relative d-lg-flex flex-column justify-content-end align-items-end h-100 px-lg-4 px-xxl-5">
                    <h1 className="text-white text-vertical px-5 px-lg-0 opacity-50 fs-xl-3 fs-xxl-4">
                      Rentals
                    </h1>
                    <img
                      className="d-none d-lg-block line-icons mt-5"
                      src="/assets/img/lineicons/map.png"
                      alt="icon"
                    />
                    <hr className="my-4 w-100 d-none d-lg-block opacity-25" />
                    <div
                      className="flex-between-center d-none d-lg-flex w-100 opacity-75"
                      data-sidebar-link="page-link"
                    >
                      <a
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        href="/services"
                      >
                        {" "}
                        <i className="fas fa-chevron-left me-lg-2 me-xl-2 me-xxl-3"></i>
                        <span className="text-capitalize">services</span>
                      </a>
                      <a
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        href="/Catalogue"
                      >
                        <span className="text-capitalize">portfolio</span>
                        <i className="fas fa-chevron-right ms-lg-2 ms-xl-2 ms-xxl-4"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RentalListComponent;
