import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { app, auth, db } from "../firebase";
import {
  Alert,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import uploadLogo from "../../src/image.png";
import videoUploadLogo from "../../src/videoUploadLogo.jpg";
import "../../src/styles.css";
import { onAuthStateChanged } from "firebase/auth";

const RentalFormComponent = () => {
  const [formData, setFormData] = useState({
    images: {
      img_1: {
        imgObj: null,
        base64String: null,
      },
      img_2: {
        imgObj: null,
        base64String: null,
      },
      img_3: {
        imgObj: null,
        base64String: null,
      },
      img_4: {
        imgObj: null,
        base64String: null,
      },
    },
    name: "",
    category: "",
    description: "",
    isSmallAvailable: false,
    isMediumAvailable: false,
    isLargeAvailable: false,
    isCustomAvailable: false,
    customSizeDesc: "",
    buyingPrice: "",
    sellingPrice: "",
    rentalPrice: "",
    phone: "",
    email: "",
    address: "",
    addrVerifyingDocument: "",
  });
  const [categoryDropdownData, setCategoryDropdownData] = useState([]);
  const [userUid, setUserUid] = useState(null);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    type: "",
    message: "",
  });
  const [userMaxIdCount, setUserMaxIdCount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const getCategoriesRef = collection(db, "customCategory");
  const { id } = useParams();
  const storage = getStorage(app);
  const navigate = useNavigate();
  const getRentalListRef = collection(db, "Rentals");
  const img1Ref = useRef(null);
  const img2Ref = useRef(null);
  const img3Ref = useRef(null);
  const img4Ref = useRef(null);

  useEffect(() => {
    const loggedUserInfo = localStorage.getItem('loggedInUser');
    if (JSON.parse(loggedUserInfo)) {
      setFormData({
        ...formData,
        phone: JSON.parse(loggedUserInfo)?.mobileNo
      })
    } else {
      navigate(`/login?navigate=rental/add`);
    }

    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    getCategories();
    getDataByProductCode(id);
    getTheMaxNumberForId();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserUid(user?.uid)
      } else {
      }
    });
  }, []);

  const getTheMaxNumberForId = async () => {
    const data = await getDocs(getRentalListRef);
    const tempRentalList = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    if (tempRentalList.length) {
      const loggedUserRentals = tempRentalList.filter((obj) => obj?.phone?.toString().indexOf(formData?.phone?.toString()) !== -1);
      const uniqueIdArr = loggedUserRentals.map((obj) => obj?.uniqueId?.toString().split('_')[1]);
      let maxCount = 0;
      await uniqueIdArr.forEach((id) => {
        if (Number(id) > maxCount) {
          maxCount = Number(id);
        }
      })
      setUserMaxIdCount(maxCount);
    } else {
      setUserMaxIdCount(0);
    }
  }

  const onFormSubmitClick = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    try {
      const userId = userUid;
      const urlArray = await Promise.all(
        Object?.keys(formData?.images).map(async (key) => {
          const url = await uploadImageInFireBase(
            formData.images?.[key]?.base64String,
            formData.images?.[key]?.imgObj,
            userId,
            key
          );
          return url;
        })
      );
      let dataToBeSubmit = { ...formData };
      await Object.keys(dataToBeSubmit.images).forEach((key, index) => {
        dataToBeSubmit = {
          ...dataToBeSubmit,
          images: {
            ...dataToBeSubmit?.images,
            [key]: {
              base64String: urlArray[index],
            },
          },
        };
      });
      setFormData({ ...formData });
      if (id) {
        updateRental(id, dataToBeSubmit);
      } else {
        createNewRental(dataToBeSubmit);
      }
      setFormData({
        name: "",
        category: "",
        description: "",
        isSmallAvailable: false,
        isMediumAvailable: false,
        isLargeAvailable: false,
        isCustomAvailable: false,
        customSizeDesc: "",
        buyingPrice: "",
        sellingPrice: "",
        rentalPrice: "",
        phone: "",
        email: "",
        address: "",
        addrVerifyingDocument: "",
      });
      handelSnackbarOpen(
        "success",
        id
          ? "Rental Order Updated Successfully"
          : "Rental Order Created Successfully"
      );
      setIsLoading(false);

      setTimeout(() => {
        navigate("/rental");
      }, 2000)
    } catch (err) {
      setFormData({
        name: "",
        category: "",
        description: "",
        isSmallAvailable: false,
        isMediumAvailable: false,
        isLargeAvailable: false,
        isCustomAvailable: false,
        customSizeDesc: "",
        buyingPrice: "",
        sellingPrice: "",
        rentalPrice: "",
        phone: "",
        email: "",
        address: "",
        addrVerifyingDocument: "",
      });
      handelSnackbarOpen("error", err?.message || "Error");
      setIsLoading(false);

      // navigate('/home');
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  };

  const handelSnackbarOpen = (type, message) => {
    setSnackbar({
      isOpen: true,
      type: type,
      message: message,
    });
  };

  const handelSnackbarClose = () => {
    setSnackbar({
      isOpen: false,
      type: "",
      message: "",
    });
  };

  const onFieldValueChange = (value, key) => {
    const data = { ...formData };
    data[key] = value;
    setFormData(data);
  };

  const getCategories = async () => {
    const data = await getDocs(getCategoriesRef);
    const tempCategoriesArr = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    if (tempCategoriesArr) {
      const finalCategoriesArr = tempCategoriesArr.map(
        (obj) => obj?.categoryName
      );
      setCategoryDropdownData(finalCategoriesArr);
    } else {
      setCategoryDropdownData([]);
    }
  };

  const createNewRental = async (formDataToBeSubmitted) => {
    console.log("createRental", formDataToBeSubmitted);
    try {
      const docRef = await addDoc(collection(db, "Rentals"), {
        ...formDataToBeSubmitted,
        createdDate: new Date(),
        uniqueId: `${formDataToBeSubmitted.phone}_${userMaxIdCount + 1}`,
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const updateRental = async (id, formDataToBeSubmitted) => {
    console.log("updateRental", formDataToBeSubmitted);
    const docRef = doc(db, "Rentals", id);

    const newData = {
      ...formDataToBeSubmitted,
    };

    try {
      await updateDoc(docRef, newData);
      console.log("Document successfully updated!");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const getDataByProductCode = async (id) => {
    if (id) {
      const data = await getDocs(collection(db, "Rentals"));
      const tempCategoriesArr = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      const apiFormData = tempCategoriesArr.filter((obj) => obj?.id == id)?.[0];
      console.log("dataOnInit", apiFormData);
      setFormData({ ...formData, ...apiFormData });
    }
  };

  const handelImageUpload = (event, key) => {
    const image = event.target.files[0];
    convertToBase64(image, key);
  };

  const convertToBase64 = (file, key) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFormData({
        ...formData,
        images: {
          ...formData.images,
          [key]: {
            imgObj: file,
            base64String: reader?.result,
          },
        },
      });
    };
  };

  const uploadImageInFireBase = async (base64String, imageObj, userId, key) => {
    if (imageObj) {
      const storageRef = ref(
        storage,
        `/public/${userId}/postId/${imageObj.name}`
      );
      try {
        const snapshot = await uploadBytes(storageRef, imageObj);
        const downloadURL = await getDownloadURL(storageRef);
        console.log("downloadUrl", downloadURL);
        return downloadURL;
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      return base64String || null;
    }
  };

  const handeluploadClick = (imgIndex) => {
    switch (imgIndex) {
      case 1:
        img1Ref.current.click();
        break;
      case 2:
        img2Ref.current.click();
        break;
      case 3:
        img3Ref.current.click();
        break;
      case 4:
        img4Ref.current.click();
        break;
      default:
    }
  };

  return (
    <div>
      <section className="page p-0 " id="contact">
        <div className="container-fluid">
          <div className="top-0 w-100">
            <div className="row">
              <div className="col-lg-8 col-xl-9 order-1 order-lg-0 pt-6 pt-lg-0">
                <section className="pt-8 pt-xl-7 pt-xxl-8 pb-0">
                  <div
                    className="bg-holder bg-size"
                    style={{
                      backgroundImage: "url(assets/img/contact-bg.png)",
                      backgroundPosition: "left center",
                      backgroundSize: "auto",
                    }}
                  ></div>

                  <div className="container-fluid">
                    <div className="row justify-content-center pb-5">
                      <div className="col-sm-9 col-lg-6">
                        <div className="text-center mb-5 mb-lg-6">
                          <h2>
                            <span className="fw-medium">
                              {id ? "Updating your rent listing" : "Rent your clothes in 1 easy step"}
                            </span>
                          </h2>
                        </div>
                        <div ref={inputRef}>
                          <form className="row g-4" data-form="data-form">
                            <label className="form-label text-700">
                              <i className="fas fa-user me-2"></i>NAME
                            </label>
                            <TextField
                              inputlabelprops={{ shrink: true }}
                              variant="outlined"
                              id="inputName"
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={(event) => {
                                onFieldValueChange(
                                  event?.target?.value,
                                  "name"
                                );
                              }}
                              className="contact-form"
                            />

                            <label className="form-label text-700">
                              <i className="fas fa-envelope me-2"></i>CATEGORY
                            </label>
                            <Select
                              inputlabelprops={{ shrink: true }}
                              variant="outlined"
                              id="inputCategory"
                              name="category"
                              value={formData.category}
                              onChange={(event) => {
                                onFieldValueChange(
                                  event?.target?.value,
                                  "category"
                                );
                              }}
                              className="contact-form"
                              style={{ margin: "0px" }}
                            >
                              {categoryDropdownData &&
                                categoryDropdownData?.map((option, index) => (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                            </Select>

                            <label className="form-label text-700">
                              <i className="fas fa-pen me-2"></i>DESCRIPTION
                            </label>
                            <TextareaAutosize
                              style={{ width: "100%", margin: "0px" }}
                              id="inputDescription"
                              type="text"
                              name="description"
                              value={formData.description}
                              onChange={(event) => {
                                onFieldValueChange(
                                  event?.target?.value,
                                  "description"
                                );
                              }}
                              minRows={3}
                              className="contact-form"
                            />

                            <label className="form-label text-700">
                              <i className="fas fa-pen me-2"></i>MEASUREMENT
                              SIZE
                            </label>
                            <FormGroup
                              aria-label="position"
                              row
                              style={{ margin: "0px" }}
                            >
                              <FormControlLabel
                                checked={formData.isSmallAvailable}
                                onChange={(event) => {
                                  onFieldValueChange(
                                    event?.target?.checked,
                                    "isSmallAvailable"
                                  );
                                }}
                                control={<Checkbox />}
                                label="S"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                checked={formData.isMediumAvailable}
                                onChange={(event) => {
                                  onFieldValueChange(
                                    event?.target?.checked,
                                    "isMediumAvailable"
                                  );
                                }}
                                control={<Checkbox />}
                                label="M"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                checked={formData.isLargeAvailable}
                                onChange={(event) => {
                                  onFieldValueChange(
                                    event?.target?.checked,
                                    "isLargeAvailable"
                                  );
                                }}
                                control={<Checkbox />}
                                label="L"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                checked={formData.isCustomAvailable}
                                onChange={(event) => {
                                  onFieldValueChange(
                                    event?.target?.checked,
                                    "isCustomAvailable"
                                  );
                                }}
                                control={<Checkbox />}
                                label="Customised"
                                labelPlacement="end"
                              />
                            </FormGroup>
                            {formData?.isCustomAvailable && (
                              <TextField
                                inputlabelprops={{ shrink: true }}
                                variant="outlined"
                                id="inputCustomSize"
                                type="text"
                                name="customSizeDesc"
                                value={formData.customSizeDesc}
                                onChange={(event) => {
                                  onFieldValueChange(
                                    event?.target?.value,
                                    "customSizeDesc"
                                  );
                                }}
                                className="contact-form"
                              />
                            )}

                            <label className="form-label text-700">
                              <i className="fas fa-user me-2"></i>BUYING PRICE
                            </label>
                            <TextField
                              inputlabelprops={{ shrink: true }}
                              variant="outlined"
                              id="inputBuyingPrice"
                              type="number"
                              name="buyingPrice"
                              value={formData.buyingPrice}
                              onChange={(event) => {
                                onFieldValueChange(
                                  event?.target?.value,
                                  "buyingPrice"
                                );
                              }}
                              className="contact-form"
                            />

                            <label className="form-label text-700">
                              <i className="fas fa-user me-2"></i>SELLING PRICE
                            </label>
                            <TextField
                              inputlabelprops={{ shrink: true }}
                              variant="outlined"
                              id="inputSellingPrice"
                              type="number"
                              name="sellingPrice"
                              value={formData.sellingPrice}
                              onChange={(event) => {
                                onFieldValueChange(
                                  event?.target?.value,
                                  "sellingPrice"
                                );
                              }}
                              className="contact-form"
                            />

                            <label className="form-label text-700">
                              <i className="fas fa-user me-2"></i>RENTAL PRICE
                              PER DAY
                            </label>
                            <TextField
                              inputlabelprops={{ shrink: true }}
                              variant="outlined"
                              id="inputRentalPrice"
                              type="number"
                              name="rentalPrice"
                              value={formData.rentalPrice}
                              onChange={(event) => {
                                onFieldValueChange(
                                  event?.target?.value,
                                  "rentalPrice"
                                );
                              }}
                              className="contact-form"
                            />

                            <label className="form-label text-700">
                              <i className="fas fa-envelope me-2"></i>PHONE
                              NUMBER
                            </label>
                            <TextField
                              inputlabelprops={{ shrink: true }}
                              id="inputPhone"
                              type="number"
                              name="phone"
                              disabled
                              placeholder="9898989898"
                              value={formData.phone}
                              onChange={(event) => {
                                onFieldValueChange(
                                  event?.target?.value,
                                  "phone"
                                );
                              }}
                              variant="outlined"
                              className="contact-form"
                            />

                            <label className="form-label text-700">
                              <i className="fas fa-user me-2"></i>EMAIL
                            </label>
                            <TextField
                              inputlabelprops={{ shrink: true }}
                              variant="outlined"
                              id="inputEmail"
                              type="text"
                              name="email"
                              value={formData.email}
                              onChange={(event) => {
                                onFieldValueChange(
                                  event?.target?.value,
                                  "email"
                                );
                              }}
                              className="contact-form"
                            />

                            <label className="form-label text-700">
                              <i className="fas fa-pen me-2"></i>ADDRESS
                            </label>
                            <TextareaAutosize
                              style={{ width: "100%", margin: "0px" }}
                              id="inputAddress"
                              type="text"
                              name="address"
                              value={formData.address}
                              onChange={(event) => {
                                onFieldValueChange(
                                  event?.target?.value,
                                  "address"
                                );
                              }}
                              minRows={3}
                              className="contact-form"
                            />

                            <div className="rental-form-image-container">
                              <div>
                                {!formData?.images?.img_1?.base64String && (
                                  <img
                                    onClick={() => {
                                      handeluploadClick(1);
                                    }}
                                    style={{
                                      height: "70px",
                                      width: "90px",
                                      cursor: "pointer",
                                    }}
                                    src={uploadLogo}
                                  ></img>
                                )}
                                {formData?.images?.img_1?.base64String && (
                                  <img
                                    onClick={() => {
                                      handeluploadClick(1);
                                    }}
                                    style={{ height: "70px", width: "70px" }}
                                    src={formData?.images?.img_1?.base64String}
                                  ></img>
                                )}
                                <input
                                  style={{ display: "none" }}
                                  ref={img1Ref}
                                  type="file"
                                  onChange={(event) => {
                                    handelImageUpload(event, "img_1");
                                  }}
                                ></input>
                              </div>

                              <div>
                                {!formData?.images?.img_2?.base64String && (
                                  <img
                                    onClick={() => {
                                      handeluploadClick(2);
                                    }}
                                    style={{
                                      height: "70px",
                                      width: "90px",
                                      cursor: "pointer",
                                    }}
                                    src={uploadLogo}
                                  ></img>
                                )}
                                {formData?.images?.img_2?.base64String && (
                                  <img
                                    onClick={() => {
                                      handeluploadClick(2);
                                    }}
                                    style={{ height: "70px", width: "70px" }}
                                    src={formData?.images?.img_2?.base64String}
                                  ></img>
                                )}
                                <input
                                  style={{ display: "none" }}
                                  ref={img2Ref}
                                  type="file"
                                  onChange={(event) => {
                                    handelImageUpload(event, "img_2");
                                  }}
                                ></input>
                              </div>

                              <div>
                                {!formData?.images?.img_3?.base64String && (
                                  <img
                                    onClick={() => {
                                      handeluploadClick(3);
                                    }}
                                    style={{
                                      height: "70px",
                                      width: "90px",
                                      cursor: "pointer",
                                    }}
                                    src={uploadLogo}
                                  ></img>
                                )}
                                {formData?.images?.img_3?.base64String && (
                                  <img
                                    onClick={() => {
                                      handeluploadClick(3);
                                    }}
                                    style={{ height: "70px", width: "70px" }}
                                    src={formData?.images?.img_3?.base64String}
                                  ></img>
                                )}
                                <input
                                  style={{ display: "none" }}
                                  ref={img3Ref}
                                  type="file"
                                  onChange={(event) => {
                                    handelImageUpload(event, "img_3");
                                  }}
                                ></input>
                              </div>
                            </div>

                            <div>
                              {!formData?.images?.img_4?.base64String && (
                                <img
                                  onClick={() => {
                                    handeluploadClick(4);
                                  }}
                                  style={{
                                    height: "150px",
                                    width: "100%",
                                    cursor: "pointer",
                                  }}
                                  src={videoUploadLogo}
                                ></img>
                              )}
                              {formData?.images?.img_4?.base64String && (
                                <div
                                  style={{
                                    height: "150px",
                                    width: "100%",
                                    marginTop: "20px",
                                  }}
                                  onClick={() => {
                                    handeluploadClick(4);
                                  }}
                                >
                                  <video width="100%" height="150px" controls>
                                    <source
                                      src={
                                        formData?.images?.img_4?.base64String
                                      }
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                              )}
                              <input
                                style={{ display: "none" }}
                                ref={img4Ref}
                                type="file"
                                onChange={(event) => {
                                  handelImageUpload(event, "img_4");
                                }}
                              ></input>
                            </div>

                            {isLoading && (
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                  }}
                                />
                              </div>
                            )}
                            <button
                              id="sign-in-button"
                              className="btn btn-boots-warning w-100 mt-5"
                              onClick={(event) => {
                                onFormSubmitClick(event);
                              }}
                              disabled={
                                !formData?.name ||
                                !formData?.category ||
                                !formData?.description ||
                                !formData?.buyingPrice ||
                                !formData?.sellingPrice ||
                                !formData?.rentalPrice ||
                                !formData?.phone ||
                                !formData?.email ||
                                !formData?.address
                              }
                            >
                              Submit
                            </button>
                            <div className="feedback"> </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-4 col-xl-3 col-12 position-absolute position-lg-relative ps-lg-0">
                <div
                  className="sticky-top py-4 sticky-area"
                  data-sidebar-close="sidebar-close"
                >
                  <div className="btn-close-boots-container times">
                    <a href="/home">
                      <div className="btn-close-boots"></div>
                    </a>
                  </div>
                  <div
                    className="bg-holder sidebar-rounded"
                    style={{
                      backgroundImage: "url(/assets/img/sidebars/contact.png)",
                    }}
                  ></div>

                  <div className="position-relative d-lg-flex flex-column justify-content-end align-items-end h-100 px-lg-4 px-xxl-5">
                    <h1 className="text-white text-vertical px-5 px-lg-0 opacity-50 fs-xl-3 fs-xxl-4">
                      Rental
                    </h1>
                    <img
                      className="d-none d-lg-block line-icons mt-5"
                      src="/assets/img/lineicons/map.png"
                      alt="icon"
                    />
                    <hr className="my-4 w-100 d-none d-lg-block opacity-25" />
                    <div
                      className="flex-between-center d-none d-lg-flex w-100 opacity-75"
                      data-sidebar-link="page-link"
                    >
                      <a
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        href="/services"
                      >
                        {" "}
                        <i className="fas fa-chevron-left me-lg-2 me-xl-2 me-xxl-3"></i>
                        <span className="text-capitalize">services</span>
                      </a>
                      <a
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        href="/Catalogue"
                      >
                        <span className="text-capitalize">Catalogue</span>
                        <i className="fas fa-chevron-right ms-lg-2 ms-xl-2 ms-xxl-4"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Snackbar
        open={snackbar.isOpen}
        autoHideDuration={8000}
        onClose={handelSnackbarClose}
      >
        <Alert
          onClose={handelSnackbarClose}
          severity={snackbar.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RentalFormComponent;
