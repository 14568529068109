import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { Button } from "@mui/material";
import { Dialog } from "@mui/material";
import "../../src/styles.css";
import { Link, useNavigate } from "react-router-dom/dist";

const BlogComponent = () => {
  const [blogsData, setBlogsData] = useState();
  const blogsRef = collection(db, "blogs");
  const navigate = useNavigate();

  useEffect(() => {
    getBlogsList();
  }, []);

  const getBlogsList = async () => {
    const data = await getDocs(blogsRef);
    const finalBlogsData = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setBlogsData(finalBlogsData);
  };

  const onBlogReadMoreClick = (blogId) => {
    navigate(`/blog/${blogId}`)
  };

  return (
    <div>
      <section className="p-0 page" id="blogs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-xl-9 order-1 order-lg-0 pt-10 pt-lg-7 pt-xxl-8 pb-0">
              <div className="row flex-center">
                <div className="col-lg-10 col-xl-8">
                  <div className="text-center mb-5 mb-lg-6">
                    <h2 className="mt-4 mt-lg-0">
                      <span className="fw-medium">Our </span>Top Blogs
                    </h2>
                  </div>
                  <div className="row justify-content-center g-3 py-3">
                    {blogsData &&
                      blogsData.map((obj) => (
                        <div className="col-sm-6 col-xl-5">
                          <Button
                            onClick={() => {
                              onBlogReadMoreClick(obj.id);
                            }}
                            style={{ padding: "0px" }}
                          >
                            <div className="card h-100">
                              <img
                                className="blog-img"
                                src={obj.photos[0] || `assets/img/blogs/blog-1.png`}
                                alt="Designer Karigar Stitching Services at your doorstep"
                              />
                              <div className="card-img-overlay card-overlay-1">
                                <h4 className="fs-lg-0 fs-xxl-1 text-white">
                                  {obj?.title}
                                </h4>
                              </div>
                            </div>
                          </Button>

                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className="col-12">
                  <div className="row justify-content-center pt-3d">
                    <div className="col-lg-8 col-xl-7 mb-n5 mb-md-n8 mb-lg-n6 mb-xl-n9 mt-8">
                      <div className="card bg-backdrop">
                        <div className="card-body p-2 px-lg-2 px-xl-5">
                          <div className="py-5 text-center">
                            <h2 className="fs-1 fs-xxl-2 fw-bolder">Book</h2>
                            <p className="mb-4 text-900">
                              Your Stitching Appointment Now!!
                            </p>
                            <a
                              className="btn hover-top btn-boots-purple"
                              href="/contact-us"
                            >
                              BOOK
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-10">
                      <img
                        className="w-100"
                        src="assets/img/cta/cta-blog.png"
                        alt="Blogs on Stitching in Noida"
                        style={{ borderRadius: "1rem" }}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center my-3">
                    <div className="col-12 col-lg-10">
                      <div className="card bg-soft-orange-2">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-lg-6 text-center text-lg-start">
                              <p className="fs--1 my-2 fw-bold text-gradient-orange-soft-2">
                                All rights Reserved &copy; AGA Designer Karigar pvt ltd. , 2024
                              </p>
                            </div>
                            <div className="col-lg-6 d-lg-flex align-self-center justify-content-end text-center">
                              <p className="fs--1 mb-0 text-gradient-orange-soft-2">
                                Made with{" "}
                                <span className="fas fa-heart mx-1 text-warning"></span>
                                by
                                <a href="https://www.designerkarigar.com/">
                                  {" "}
                                  Designer Karigar
                                </a>
                              </p>
                              <img
                                src="/assets/img/logos/diamond.png"
                                alt="Designer Karigar Book Your Order logo"
                                className="very-small-logo"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-3 col-12 position-absolute position-lg-relative ps-lg-0">
              <div
                className="sticky-top py-4 sticky-area"
                data-sidebar-close="sidebar-close"
              >
                <a href="/"><div className="btn-close-boots-container times">
                  <div className="btn-close-boots"></div>
                </div></a>
                <div
                  className="bg-holder sidebar-rounded"
                  style={{
                    backgroundImage: "url(assets/img/sidebars/blogs.png)",
                  }}
                ></div>

                <div className="position-relative d-lg-flex flex-column justify-content-end align-items-end h-100 px-lg-4 px-xxl-5">
                  <h1 className="text-white text-vertical px-5 px-lg-0 opacity-50 fs-xl-3 fs-xxl-4">
                    Blogs
                  </h1>
                  <img
                    className="d-none d-lg-block line-icons mt-5"
                    src="assets/img/lineicons/pencil.png"
                    alt="Stitching Details in Noida"
                  />
                  <hr className="my-4 w-100 d-none d-lg-block opacity-25" />
                  <div
                    className="flex-between-center d-none d-lg-flex w-100 opacity-75"
                    data-sidebar-link="page-link"
                  >
                    <Link
                      className="sidebar-nav btn btn-link text-decoration-none px-1"
                      to="/Catalogue"
                    >
                      {" "}
                      <i className="fas fa-chevron-left me-lg-2 me-xl-2 me-xxl-3"></i>
                      <span className="text-capitalize">Catalogue</span>
                    </Link>
                    <Link
                      className="sidebar-nav btn btn-link text-decoration-none px-1"
                      to="/gallery"
                    >
                      <span className="text-capitalize">Boutique</span>
                      <i className="fas fa-chevron-right ms-lg-2 ms-xl-2 ms-xxl-4"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Dialog
        open={isModalVisible}
        onClose={handelModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ height: "100vh", textAlign: "center", padding: "20px" }}>
          <div
            className="blog-modal-content"
            dangerouslySetInnerHTML={{ __html: modalBlogDetails?.Body }}
          ></div>
        </div>
      </Dialog> */}
    </div>
  );
};

export default BlogComponent;
