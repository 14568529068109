import React from "react";
import BannerComponent from "../components/BannerComponent";
import MenuComponent from "../components/MenuComponent";
import DocumentMeta from 'react-document-meta';




const HomePageComponent = () => {
  const meta = {
    title: 'Stitching in Noida | Stitching in Gurugram',
    description: 'Designer Karigar Boutique in Noida',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'Stitching, Tailor, Suits, Lehenga, Dress Designs, Sarees, Blouse Designs'
      }
    }
  }
  return (
    <div>
      <DocumentMeta {...meta} />
      <BannerComponent show="show"></BannerComponent>
      <MenuComponent></MenuComponent>
    </div>
  );
};

export default HomePageComponent;
