import React, { useEffect } from "react";
import { useState } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useParams } from "react-router-dom/dist";
import "../../src/styles.css";

const BlogDetailsComponent = () => {
  const [blogData, setBlogData] = useState();
  let { blogId } = useParams();

  useEffect(() => {
    getBlogById();
  }, []);

  const getBlogById = async () => {
    const blogsRef = collection(db, "blogs");
    const data = await getDocs(blogsRef);
    const finalBlogsData = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    finalBlogsData.forEach((obj) => {
      if (obj?.id === blogId) {
        setBlogData(obj);
      }
    });
  };

  return (
    <div>
      <div>
        <section className="p-0 page" id="blogs">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8 col-xl-9 order-1 order-lg-0 pt-10 pt-lg-7 pt-xxl-8 pb-0">
                <div className="row flex-center">
                  <div className="col-lg-10 col-xl-8">
                    <div className="row justify-content-center g-3 py-3">
                      <div
                        className="blog-modal-content"
                        style={{ textAlign: "center" }}
                        dangerouslySetInnerHTML={{
                          __html: blogData?.Body,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row justify-content-center pt-3d">
                      <div className="col-lg-8 col-xl-7 mb-n5 mb-md-n8 mb-lg-n6 mb-xl-n9 mt-8">
                        <div className="card bg-backdrop">
                          <div className="card-body p-2 px-lg-2 px-xl-5">
                            <div className="py-5 text-center">
                              <h2 className="fs-1 fs-xxl-2 fw-bolder">Book</h2>
                              <p className="mb-4 text-900">
                                Your Stitching Appointment Now!!
                              </p>
                              <a
                                className="btn hover-top btn-boots-purple"
                                href="/contact-us"
                              >
                                BOOK
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-10">
                        <img
                          className="w-100"
                          src="/assets/img/cta/cta-blog.png"
                          alt="Designer Karigar Blogs"
                          style={{ borderRadius: "1rem" }}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center my-3">
                      <div className="col-12 col-lg-10">
                        <div className="card bg-soft-orange-2">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-lg-6 text-center text-lg-start">
                                <p className="fs--1 my-2 fw-bold text-gradient-orange-soft-2">
                                  All rights Reserved &copy; AGA Designer Karigar pvt ltd. , 2024
                                </p>
                              </div>
                              <div className="col-lg-6 d-lg-flex align-self-center justify-content-end text-center">
                                <p className="fs--1 mb-0 text-gradient-orange-soft-2">
                                  Made with{" "}
                                  <span className="fas fa-heart mx-1 text-warning"></span>
                                  by
                                  <a href="https://www.designerkarigar.com/">
                                    {" "}
                                    Designer Karigar
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3 col-12 position-absolute position-lg-relative ps-lg-0">
                <div
                  className="sticky-top py-4 sticky-area"
                  data-sidebar-close="sidebar-close"
                >
                  <a href="/blog"><div className="btn-close-boots-container times">
                    <div className="btn-close-boots"></div>
                  </div></a>
                  <div
                    className="bg-holder sidebar-rounded"
                    style={{
                      backgroundImage: "url(/assets/img/sidebars/blogs.png)",
                    }}
                  ></div>

                  <div className="position-relative d-lg-flex flex-column justify-content-end align-items-end h-100 px-lg-4 px-xxl-5">
                    <h1 className="text-white text-vertical px-5 px-lg-0 opacity-50 fs-xl-3 fs-xxl-4">
                      Blogs
                    </h1>
                    <img
                      className="d-none d-lg-block line-icons mt-5"
                      src="/assets/img/lineicons/pencil.png"
                      alt="Designer Karigar Blogs"
                    />
                    <hr className="my-4 w-100 d-none d-lg-block opacity-25" />
                    <div
                      className="flex-between-center d-none d-lg-flex w-100 opacity-75"
                      data-sidebar-link="page-link"
                    >
                      <a
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        href="/Catalogue"
                      >
                        {" "}
                        <i className="fas fa-chevron-left me-lg-2 me-xl-2 me-xxl-3"></i>
                        <span className="text-capitalize">Catalogue</span>
                      </a>
                      <a
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        href="/gallery"
                      >
                        <span className="text-capitalize">gallery</span>
                        <i className="fas fa-chevron-right ms-lg-2 ms-xl-2 ms-xxl-4"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BlogDetailsComponent;
