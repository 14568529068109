import { addDoc, collection } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { app, auth, db } from "../firebase";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  TextareaAutosize,
  Select,
  MenuItem
} from "@mui/material";
import "../../src/styles.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuid } from "uuid";


const ContactUsComponent = () => {
  const unique_id = uuid();
  const small_id = unique_id.slice(0, 4);
  const [formData, setFormData] = useState({
    customerName: "",
    city: "",
    customerAddress: "",
    phone_Number: "",
    visitDate: "",
    orderStatus: "New",
    orderid: small_id,
    orderDate: new Date().toISOString() + "",
    to: '+919999272239',
    orderDetails: ""
  });
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    type: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    const loggedUserInfo = localStorage.getItem("loggedInUser");
    if (JSON.parse(loggedUserInfo)) {
      setFormData({
        ...formData,
        phone_Number: JSON.parse(loggedUserInfo)?.mobileNo,
      });
    } else {
      navigate(`/login?navigate=contact-us`);
    }
  }, []);

  const handelSnackbarClose = () => {
    setSnackbar({
      isOpen: false,
      type: "",
      message: "",
    });
  };

  const handelSnackbarOpen = (type, message) => {
    setSnackbar({
      isOpen: true,
      type: type,
      message: message,
    });
  };

  const onFieldValueChange = (e, key) => {
    const data = { ...formData };
    data[key] = e.target.value;
    setFormData(data);
  };
  const onFieldValueChangeOrder = (e, key) => {
    const data = { ...formData };
    data[key] = e.target.value.split("\n");
    setFormData(data);
  };

  const onSubmitClick = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    try {
      formData.phone_Number = "+91" + formData.phone_Number;
      formData.body = `Designer Karigar order from ${formData.customerName} ${formData.city} ${formData.orderid} ${formData.orderDetails}`
      //formData.visitDate = formData.visitDate.toISOString();
      //const myDate = formData.visitDate.split("-"); //25-05-2024
      var newDate = new Date();
      console.log(newDate);
      //formData.from = "whatsapp:+917678500214";
      //formData.to = "whatsapp:+919999272239";
      formData.visitDate = newDate;
      const docRef = await addDoc(collection(db, "Orders"), {
        ...formData,
        createdTimeStamp: new Date(),
      });
      handelSnackbarOpen("success", "Order Placed Successfully !");
      setIsLoading(false);

      // navigate('/home');
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    } catch (e) {
      console.error("Error placing Order ", e);
      handelSnackbarOpen("error", e.message);
      setIsLoading(false);

      // navigate('/home');
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  };

  return (
    <div>
      <section className="page p-0 " id="contact">
        <div className="container-fluid">
          <div className="top-0 w-100">
            <div className="row">
              <div className="col-lg-8 col-xl-9 order-1 order-lg-0 pt-6 pt-lg-0">
                <section className="pt-8 pt-xl-7 pt-xxl-8 pb-0">
                  <div
                    className="bg-holder bg-size"
                    style={{
                      backgroundImage: "url(assets/img/contact-bg.png)",
                      backgroundPosition: "left center",
                      backgroundSize: "auto",
                    }}
                  ></div>

                  <div className="container-fluid">
                    <div className="row justify-content-center pb-5">
                      <div className="col-sm-9 col-lg-6">
                        <div className="text-center mb-5 mb-lg-6">
                          <h2>
                            <span className="fw-medium">Get In </span>Touch
                          </h2>
                        </div>
                        <h4>Connect with us</h4>
                        <p className="mb-7">
                          Get Your Designer Clothes Stitching from your Home
                          Comfort
                          <br className="d-none d-xxl-block" />
                          Order Now
                        </p>
                        <div className="d-flex align-items-center mb-2">
                          <div className="icon icon-warning shadow-1">
                            <i className="fas fa-phone_Number fs-1"></i>
                          </div>
                          <div className="flex-1 ms-3">
                            <p className="fw-bold mb-0 text-gradient-orange-1">
                              <a href="tel:+918826738307">+918826738307</a>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div className="icon icon-warning shadow-2">
                            <i className="fas fa-envelope fs-1"></i>
                          </div>
                          <div className="flex-1 ms-3">
                            <p className="fw-bold mb-0 text-gradient-orange-2">
                              {" "}
                              <a href="mailto:designerkarigar@gmail.com ">
                                designerkarigar@gmail.com
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="icon icon-warning fs-2 shadow-3">
                            <i className="fas fa-map-marker-alt"></i>
                          </div>
                          <div className="flex-1 ms-3">
                            <p className="fw-bold text-gradient-orange-3 mb-0">
                              Noida, South Delhi & Gurugram
                            </p>
                          </div>
                        </div>
                        <hr className="my-4 text-200" />
                        <div className="text-center mb-5 mb-lg-6">
                          <h3 className="mb-5"><span><img
                            src="/assets/img/logos/diamond.png"
                            alt="Designer Karigar Book Your Order logo"
                            className="very-small-logo"
                          /> Follow Designer Karigar</span></h3>

                          <ul className="list-unstyled list-inline">
                            <li className="list-inline-item mx-2">
                              <a href="https://www.facebook.com/designerkarigar/">
                                <img
                                  src="assets/img/icons/facebook.png"
                                  alt="..."
                                />
                              </a>
                            </li>
                            <li className="list-inline-item mx-2">
                              <a href="https://www.instagram.com/designerkarigar/?hl=en">
                                <img
                                  src="assets/img/icons/twitter.png"
                                  alt="..."
                                />
                              </a>
                            </li>
                            <li className="list-inline-item mx-2">
                              <a href="#!">
                                <img
                                  src="assets/img/icons/youtube.png"
                                  alt="..."
                                />
                              </a>
                            </li>
                            <li className="list-inline-item mx-2">
                              <a href="#!">
                                <img
                                  src="assets/img/icons/linkdin.png"
                                  alt="..."
                                />
                              </a>
                            </li>
                            <li className="list-inline-item mx-2">
                              <a href="#!">
                                <img
                                  src="assets/img/icons/google-plus.png"
                                  alt="..."
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <hr className="my-6 text-200" />
                        <h4>Book Your Stitching Order</h4>
                        <p className="mb-7">
                          Our Team will co-ordinate with your stitching order details, Time to visit for your measurements and designs finalisations.
                        </p>
                        <div ref={inputRef}>
                          <form className="row g-4" data-form="data-form">
                            <label className="form-label text-700">
                              <i className="fas fa-user me-2"></i>NAME
                            </label>
                            <TextField
                              inputlabelprops={{ shrink: true }}
                              variant="outlined"
                              id="customerName"
                              type="text"
                              name="customerName"
                              value={formData.customerName}
                              onChange={(event) => {
                                onFieldValueChange(event, "customerName");
                              }}
                              className="contact-form"
                            />

                            <label className="form-label text-700">
                              <i className="fas fa-city me-2"></i>CITY(Noida
                              or Gurgoan)
                            </label>
                            <Select
                              inputlabelprops={{ shrink: true }}
                              variant="outlined"
                              id="inputCity"
                              name="city"
                              value={formData.city}
                              onChange={(event) => {
                                if (event) {
                                  onFieldValueChange(
                                    event,
                                    "city"
                                  );
                                }
                              }}
                              className="contact-form"
                              style={{ margin: "0px" }}
                            >
                              <MenuItem value='Noida'>
                                Noida
                              </MenuItem>
                              <MenuItem value='Gurugram'>
                                Gurugram
                              </MenuItem>
                            </Select>

                            <label className="form-label text-700">
                              <i className="fas fa-pen me-2"></i>ADDRESS
                            </label>
                            <TextareaAutosize
                              style={{ width: "100%", margin: "0px" }}
                              id="customerAddress"
                              type="text"
                              name="customerAddress"
                              value={formData.customerAddress}
                              onChange={(event) => {
                                onFieldValueChange(event, "customerAddress");
                              }}
                              minRows={3}
                              className="contact-form"
                            />

                            <label className="form-label text-700">
                              <i className="fas fa-mobile me-2"></i>
                              Phone Number
                            </label>
                            <TextField
                              inputlabelprops={{ shrink: true }}
                              id="inputphone_Number"
                              type="number"
                              disabled
                              placeholder="XXXXXXXXXX"
                              name="phone_Number"
                              value={formData.phone_Number}
                              onChange={(event) => {
                                onFieldValueChange(event, "phone_Number");
                              }}
                              variant="outlined"
                              className="contact-form"
                            />

                            <label className="form-label text-700">
                              <i className="fas fa-calendar me-2"></i>DATE
                            </label>
                            <TextField
                              inputlabelprops={{ shrink: true, required: true }}
                              type="date"
                              id="inputvisitDate"
                              name="visitDate"
                              InputProps={{ inputProps: { min: new Date() } }}
                              value={formData.visitDate}
                              onChange={(event) => {
                                onFieldValueChange(event, "visitDate");
                              }}
                              variant="outlined"
                              className="contact-form"
                            />
                            <label className="form-label text-700">
                              <i className="fas fa-box me-2"></i>Order Details (minimum 2 pieces)
                            </label>
                            <TextareaAutosize
                              style={{ width: "100%", margin: "0px" }}
                              id="orderDetails"
                              type="text"
                              name="orderDetails"
                              value={formData.orderDetails}
                              onChange={(event) => {
                                onFieldValueChangeOrder(event, "orderDetails");
                              }}
                              minRows={2}
                              className="contact-form"
                            />
                            {isLoading && (
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                  }}
                                />
                              </div>
                            )}
                            <button
                              id="sign-in-button"
                              className="btn btn-boots-warning w-100 mt-5"
                              onClick={(event) => {
                                onSubmitClick(event);
                              }}
                              disabled={
                                !formData?.customerName ||
                                !formData?.city ||
                                !formData?.customerAddress ||
                                !formData?.phone_Number ||
                                !formData?.visitDate
                              }
                            >
                              Submit
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center my-3">
                      <div className="col-12 col-lg-10">
                        <div className="card bg-soft-orange-1">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-lg-6 text-center text-lg-start">
                                <p className="fs--1 my-2 fw-bold text-gradient-orange-soft-1">
                                  All rights Reserved &copy; AGA Designer
                                  Karigar pvt ltd. , 2024
                                </p>
                              </div>
                              <div className="col-lg-6 d-lg-flex align-self-center justify-content-end text-center">
                                <p className="fs--1 mb-0 text-gradient-orange-soft-1">
                                  Made with{" "}
                                  <span className="fas fa-heart mx-1 text-warning"></span>
                                  by
                                  <a href="https://www.designerkarigar.com/">
                                    {" "}
                                    Designer Karigar
                                  </a>
                                </p>
                                <img
                                  src="/assets/img/logos/diamond.png"
                                  alt="Designer Karigar Book Your Order logo"
                                  className="very-small-logo"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-4 col-xl-3 col-12 position-absolute position-lg-relative ps-lg-0">
                <div
                  className="sticky-top py-4 sticky-area"
                  data-sidebar-close="sidebar-close"
                >
                  <a href="/">
                    <div className="btn-close-boots-container times">
                      <div className="btn-close-boots"></div>
                    </div>
                  </a>
                  <div
                    className="bg-holder sidebar-rounded"
                    style={{
                      backgroundImage: "url(assets/img/sidebars/contact.png)",
                    }}
                  ></div>

                  <div className="position-relative d-lg-flex flex-column justify-content-end align-items-end h-100 px-lg-4 px-xxl-5">
                    <h1 className="text-white text-vertical px-5 px-lg-0 opacity-50 fs-xl-3 fs-xxl-4">
                      Contact
                    </h1>
                    <img
                      className="d-none d-lg-block line-icons mt-5"
                      src="assets/img/lineicons/map.png"
                      alt="icon"
                    />
                    <hr className="my-4 w-100 d-none d-lg-block opacity-25" />
                    <div
                      className="flex-between-center d-none d-lg-flex w-100 opacity-75"
                      data-sidebar-link="page-link"
                    >
                      <Link
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        to="/services"
                      >
                        {" "}
                        <i className="fas fa-chevron-left me-lg-2 me-xl-2 me-xxl-3"></i>
                        <span className="text-capitalize">services</span>
                      </Link>
                      <Link
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        to="/Catalogue"
                      >
                        <span className="text-capitalize">Designs</span>
                        <i className="fas fa-chevron-right ms-lg-2 ms-xl-2 ms-xxl-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Snackbar
        open={snackbar.isOpen}
        autoHideDuration={8000}
        onClose={handelSnackbarClose}
      >
        <Alert
          onClose={handelSnackbarClose}
          severity={snackbar.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContactUsComponent;
