import React from 'react'
import { Link } from 'react-router-dom';

const MenuComponent = () => {
    return (
        <div className="offcanvas offcanvas-end home-nav px-0 show" id="offcanvasEnd" tabIndex="-1" data-bs-backdrop="false" data-bs-scroll="true" data-base-content="nav">
            <div className="offcanvas-body p-0 position-lg-fixed position-relative">
                <div className="row vh-lg-100 g-0">
                    <div className="col-6 col-sm-4 vh-25 vh-lg-50 position-relative text-white nav-hover-zoom nav-white-soft"><img className="nav-img h-100" src="assets/img/navigation/about-6-3.png" alt="Designer Karigar About Us" />
                        <div className="card-img-overlay d-flex flex-column flex-center align-items-xxl-start justify-content-xxl-end h-100 px-5 pb-4">
                            <div className="mb-3"> <img className="nav-icons" src="assets/img/lineicons/About-6-3.png" alt="Designer Karigar Founders" /></div><Link className="text-light fs-1 fs-xl-2 fs-xxl-3 stretched-link items fw-bold text-decoration-none" to="/about-us">About</Link>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 vh-25 vh-lg-50 position-relative text-white nav-hover-zoom nav-purple-soft"><img className="nav-img h-100" src="assets/img/navigation/services-6-3.png" alt="Designer Karogar Process" />
                        <div className="card-img-overlay d-flex flex-column flex-center align-items-xxl-start justify-content-xxl-end h-100 px-5 pb-4">
                            <div className="mb-3"> <img className="nav-icons" src="assets/img/lineicons/Stitching-Process-6-4.png" alt="Best Stitching in Noida Gurgram" /></div><Link className="text-light fs-1 fs-xl-2 fs-xxl-3 stretched-link items fw-bold text-decoration-none" to="/services">Stitching</Link>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 vh-25 vh-lg-50 position-relative text-white nav-hover-zoom nav-cyan-dark"><img className="nav-img h-100" src="assets/img/navigation/contact-6-3.png" alt="Designer Karigar Book Stitching" />
                        <div className="card-img-overlay d-flex flex-column flex-center align-items-xxl-start justify-content-xxl-end h-100 px-5 pb-4">
                            <div className="mb-3"> <img className="nav-icons" src="assets/img/lineicons/map-6-4.png" alt="Book Designer Karigar for all stitching needs" /></div><Link className="text-light fs-1 fs-xl-2 fs-xxl-3 stretched-link items fw-bold text-decoration-none" to="/contact-us">Book</Link>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 vh-25 vh-lg-50 position-relative text-white nav-hover-zoom nav-blue-dark"><img className="nav-img h-100" src="assets/img/navigation/portfolio-6-3.png" alt="Designer Karigar latest Designs" />
                        <div className="card-img-overlay d-flex flex-column flex-center align-items-xxl-start justify-content-xxl-end h-100 px-5 pb-4">
                            <div className="mb-3"> <img className="nav-icons" src="assets/img/lineicons/catalogue-6-3.png" alt="Best Designs for your stitching" /></div><Link className="text-light fs-1 fs-xl-2 fs-xxl-3 stretched-link items fw-bold text-decoration-none" to="/Catalogue">Designs</Link>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 vh-25 vh-lg-50 position-relative text-white nav-hover-zoom nav-purple-dark"><img className="nav-img h-100" src="assets/img/navigation/blog-6-3.png" alt="Blogs on Designer Karigar" />
                        <div className="card-img-overlay d-flex flex-column flex-center align-items-xxl-start justify-content-xxl-end h-100 px-5 pb-4">
                            <div className="mb-3"> <img className="nav-icons" src="assets/img/lineicons/pencil-6-3.png" alt="Best Stitching trends" /></div><Link className="text-light fs-1 fs-xl-2 fs-xxl-3 stretched-link items fw-bold text-decoration-none" to="/blog">Trends</Link>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 vh-25 vh-lg-50 position-relative text-white nav-hover-zoom nav-cyan-soft"><img className="nav-img h-100" src="assets/img/navigation/gallery-6-3.png" alt="Designer Karigar Boutique Services" />
                        <div className="card-img-overlay d-flex flex-column flex-center align-items-xxl-start justify-content-xxl-end h-100 px-5 pb-4">
                            <div className="mb-3"> <img className="nav-icons" src="assets/img/lineicons/Boutique-8-3.png" alt="Best Stitching Boutique in Noida" /></div><Link className="text-light fs-1 fs-xl-2 fs-xxl-3 stretched-link items fw-bold text-decoration-none" to="/gallery">Boutique</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuComponent;

