import React from "react";
import { Link } from "react-router-dom";


const AboutUsComponent = () => {
  return (
    <section className="p-0 page" id="about">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-xl-9 order-1 order-lg-0 pt-10 pt-lg-8 pt-xl-7 pt-xxl-8 pb-0 px-lg-0">
            <div className="row flex-center" id="bigpicture">
              <div className="col-11 col-xl-7">
                <div className="text-center mb-5 mb-lg-6">
                  <h2>
                    <span className="fw-medium">What we do? How we do?</span> <p>Designer
                      Karigar</p>
                  </h2>
                  <img
                    src="/assets/img/logos/diamond.png"
                    alt="Designer Karigar Book Your Order logo"
                    className="very-small-logo"
                  />
                </div>
                <div className="position-relative">
                  {" "}
                  <iframe width="100%" height="365"
                    src="https://www.youtube.com/embed/1q8CyqgT7bQ">
                  </iframe>
                </div>
                <h3 className="pb-4 pt-5">Hassle Free <a href='../services'>Stitching!!!!</a> </h3>

                <p className="text-1000 fs-1">
                  At Designer Karigar, We specializes in providing expert
                  tailoring services and creating exquisite designs
                </p>
                <p className="text-800 mb-5">
                  for various garments, including but not limited to <Link to='../Catalogue/Suits-Designs'>suits</Link>,
                  <Link to='../Catalogue/Lehenga-Skirts-designs'> lehengas</Link>, <Link to='../Catalogue/Ready-to-wear-saree(Drape-saree)'>sarees</Link>, <Link to='../Catalogue/Bridal-Blouses'>blouses</Link>, and <a href='../Catalogue/Co-Ord-sets-designs'>more</a>.
                  <br />
                  Looking for the perfect ladies tailoring shop near you?
                  Designer Karigar is your ultimate destination, offering a wide
                  array of services including exquisite <a href='../Catalogue/Padded-Blouses'>blouse designs</a> and
                  lehengas. <br />
                  Our expert tailors specialize in creating stunning
                  designs tailored to perfection, ensuring that you stand out at
                  any occasion. Whether you're searching for the latest blouse
                  designs or need expert tailoring services, <br />
                  Designer Karigar is the tailor's near you that you can trust.
                  <a href="tel:8826738307"> Call</a> our tailoring store today and experience the epitome of
                  style and elegance.
                </p>
                <div className="row flex-center pt-3 g-xxl-5" id="fun-facts">
                  <div className="col-12 col-sm-4 mb-4 h-100">
                    <div className="card card-span h-100 card-bg-line">
                      <div className="card-body py-8 d-flex flex-center">
                        <img
                          className="shadow-icon-1"
                          src="assets/img/Stitching-Orders.png"
                          alt="Stitching Orders"
                        />
                      </div>
                    </div>
                    <h1
                      className="fs-lg-2 fs-xxl-4 mt-3 fw-bold text-center text-gradient-blue"
                      data-countup='{"endValue":4643}'
                    >
                      4000+
                    </h1>
                    <p className="text-900 fw-bold text-center fs-xxl-1">
                      Orders Closed
                    </p>
                  </div>
                  <div className="col-12 col-sm-4 mb-4 h-100">
                    <div className="card card-span h-100 card-bg-coffee">
                      <div className="card-body py-8 d-flex flex-center">
                        <a href='https://g.co/kgs/iFjUQcB'><img
                          className="shadow-icon-2"
                          src="assets/img/icons/google2.png"
                          alt="Stitching Google Reviews"
                        /></a>
                      </div>
                    </div>
                    <a href='https://g.co/kgs/iFjUQcB'>
                      <h1
                        className="fs-lg-2 fs-xxl-4 mt-3 fw-bold text-center text-gradient-purple"
                        data-countup='{"endValue":4643}'
                      >
                        500+
                      </h1>
                      <p className="text-900 fw-bold text-center fs-xxl-1">
                        Google Reviews
                      </p>
                    </a>
                  </div>
                  <div className="col-12 col-sm-4 mb-4 h-100">
                    <div className="card card-span h-100 card-bg-download">
                      <div className="card-body py-8 d-flex flex-center">
                        <img
                          className="shadow-icon-3"
                          src="assets/img/OutfitsStiched.png"
                          alt="Outfits Stiched"
                        />
                      </div>
                    </div>
                    <h1
                      className="fs-lg-2 fs-xxl-4 mt-3 fw-bold text-center text-gradient-pink-1"
                      data-countup='{"endValue":4643}'
                    >
                      12000+
                    </h1>
                    <p className="text-900 fw-bold text-center fs-xxl-1">
                      Outfits Stitched
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center pt-6 g-2">
                  <div className="text-center mb-5 mb-lg-6">
                    <h2 className="fs-2 fs-sm-3">
                      {" "}
                      <span className="fw-medium">Meet &nbsp;</span>Our Team
                    </h2>
                  </div>
                  <div className="col-lg-11 col-xxl-9">
                    <div className="row justify-content-center">
                      <div className="col-6 mb-5">
                        <div className="position-relative d-flex flex-center flex-column">
                          <img
                            className="img-fluid rounded-circle"
                            src="assets/img/team/Anjali-DesignerKarigar.jpg"
                            width="242"
                            alt="Anjali from DesignerKarigar"
                          />
                          <h5 className="fw-bold mt-3 mb-1">Anjali</h5>
                          <h5 className="fw-normal text-800 mb-1">Founder</h5>
                          <a className="stretched-link" href="#!"></a>
                        </div>
                      </div>
                      <div className="col-6 mb-5">
                        <div className="position-relative d-flex flex-center flex-column">
                          <img
                            className="img-fluid rounded-circle"
                            src="assets/img/team/Asha-DesignerKarigar.jpg"
                            width="242"
                            alt="Asha from Designer Karigar"
                          />
                          <h5 className="fw-bold mt-3 mb-1">Asha</h5>
                          <h5 className="fw-normal text-800 mb-1">
                            Operations Head
                          </h5>
                          <a className="stretched-link" href="#!"></a>
                        </div>
                      </div>
                      <div className="col-6 col-sm-4 col-auto mb-5"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center pt-3d">
              <div className="col-lg-8 col-xl-7 mb-n5 mb-md-n8 mb-lg-n6 mb-xl-n9 mt-8">
                <div className="card bg-backdrop">
                  <div className="card-body p-2 px-lg-2 px-xl-5">
                    <div className="py-5 text-center">
                      <h2 className="fs-1 fs-xxl-2 fw-bolder">Book</h2>
                      <p className="mb-4 text-900">
                        Your Stitching Appointment Now!!
                      </p>
                      <a className="btn hover-top btn-boots-warning" href="/contact-us">
                        BOOK
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-10">
                <img
                  className="w-100"
                  src="assets/img/cta/cta-about.png"
                  alt="Know About Boutique Stitching Near Noida"
                  style={{ borderRadius: "1rem" }}
                />
              </div>
            </div>
            <div className="row justify-content-center my-3">
              <div className="col-12 col-lg-10">
                <div className="card bg-soft-blue">
                  <div className="card-body py-3">
                    <div className="row">
                      <div className="col-lg-6 text-center text-lg-start">
                        <p className="fs--1 my-2 fw-bold text-gradient-blue-soft">
                          All rights Reserved &copy; AGA Designer Karigar pvt ltd. 2024
                        </p>
                      </div>
                      <div className="col-lg-6 d-lg-flex align-self-center justify-content-end text-center">
                        <p className="fs--1 mb-0 text-gradient-blue-soft">
                          Made with{" "}
                          <span className="fas fa-heart mx-1 text-primary"></span>
                          by<a href="https://designerkarigar.com/"> Designer Karigar</a>
                        </p>
                        <img
                          src="/assets/img/logos/diamond.png"
                          alt="Designer Karigar Book Your Order logo"
                          className="very-small-logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-3 position-absolute position-lg-relative ps-lg-0">
            <div
              className="sticky-top py-4 sticky-area"
              data-sidebar-close="sidebar-close"
            >
              <a href="/"><div className="btn-close-boots-container times">
                <div className="btn-close-boots"></div>
              </div></a>
              <div
                className="bg-holder sidebar-rounded"
                style={{
                  backgroundImage: "url(assets/img/sidebars/about.png)",
                }}
              ></div>

              <div className="position-relative d-lg-flex flex-column justify-content-end align-items-end h-100 px-lg-4 px-xxl-5">
                <h1 className="text-white text-vertical px-5 px-lg-0 opacity-50 fs-xl-3 fs-xxl-4">
                  About Us
                </h1>
                <img
                  className="d-none d-lg-block line-icons mt-5"
                  src="assets/img/lineicons/About.png"
                  alt="Know About Designer Boutique in Noida"
                />
                <hr className="my-4 w-100 d-none d-lg-block opacity-25" />
                <div
                  className="flex-between-center d-none d-lg-flex w-100 opacity-75"
                  data-sidebar-link="page-link"
                >
                  <Link
                    className="sidebar-nav btn btn-link text-decoration-none px-1"
                    to="/gallery"
                  >
                    {" "}
                    <i className="fas fa-chevron-left me-lg-2 me-xl-2 me-xxl-3"></i>
                    <span className="text-capitalize">gallery</span>
                  </Link>
                  <Link
                    className="sidebar-nav btn btn-link text-decoration-none px-1"
                    to="/services"
                  >
                    <span className="text-capitalize">services</span>
                    <i className="fas fa-chevron-right ms-lg-2 ms-xl-2 ms-xxl-4"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AboutUsComponent;
