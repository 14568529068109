import React from "react";
import DocumentMeta from 'react-document-meta';
import { Link } from "react-router-dom";

const ServicesComponent = () => {
  const meta = {
    title: 'Process of placing Stitching Order',
    description: 'Process of Stitching in Noida | Gurgaon Gurugram',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'stitching process, tailoring process, designing suits, Lehenga designs'
      }
    }
  }
  return (
    <>
      <DocumentMeta {...meta} />
      <div>
        <section className="p-0 page" id="services">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8 col-xl-9 order-1 order-lg-0 pt-8 pt-lg-0 pb-0 px-lg-0">
                <div className="row min-vh-25 flex-center">
                  <div className="col-lg-10">
                    <div className="card card-span">
                      <div
                        className="bg-holder bg-size "
                        style={{
                          backgroundImage:
                            "url(assets/img/services/services-card-bg.png)",
                          backgroundPosition: "top center",
                          backgroundSize: "cover",
                          borderEndEndRadius: "5.5rem",
                          borderEndStartRadius: "5.5rem",
                        }}
                      ></div>

                      <div className="card-body position-relative">
                        <div className="text-center my-3">
                          <h2 className="fs-1 fs-md-2 fs-lg-3">
                            How We Do Stitching & Designing?
                          </h2>
                          <div className="row flex-center">
                            <div className="col-xl-9 col-xxl-8 my-6">
                              <div className="d-sm-flex flex-center">
                                <img
                                  className="order-0 pe-4"
                                  src="assets/img/services/application.png"
                                  alt="Process of Designer Stitching"
                                />
                                <div className="flex-1 text-center text-sm-start">
                                  <h3 className="mb-3 fw-bolder text-gradient-orange-1">
                                    Understand
                                  </h3>
                                  <p className="mb-0 text-1200">
                                    Our Social Media Handler understands your
                                    requirements thoroughly
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-9 col-xxl-8 my-6">
                              <div className="d-sm-flex flex-center">
                                <img
                                  className="order-1 ps-4"
                                  src="assets/img/services/design.png"
                                  alt="Noida Stitching"
                                />
                                <div className="flex-1 text-center text-sm-start">
                                  <h3 className="mb-3 fw-bolder text-gradient-pink-1">
                                    Schedule Appointment
                                  </h3>
                                  <p className="mb-0 text-1200">
                                    Based on your requirement, We book a relevant
                                    Designer Appointment for pickup, Designs
                                    Finalisation and measurements
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-9 col-xxl-8 my-6">
                              <div className="d-sm-flex flex-center">
                                <img
                                  className="order-0 pe-4"
                                  src="assets/img/services/DesignerVisitHomeMeasurement.png"
                                  alt="Tailor in Noida With Designer Visit"
                                />
                                <div className="flex-1 text-center text-sm-start">
                                  <h3 className="mb-3 fw-bolder text-gradient-blue">
                                    Designer Visits
                                  </h3>
                                  <p className="mb-0 text-1200">
                                    Our Expert Designers take your measurements, designs
                                    comes back to our in house Stitching Hub
                                    and collaborate with Masters and Karigars
                                    to make your custom outfits.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-9 col-xxl-8 my-6">
                              <div className="d-sm-flex flex-center">
                                <img
                                  className="order-1 pe-4"
                                  src="assets/img/services/StitchingHandwork.png"
                                  alt="Tailored Clothes"
                                />
                                <div className="flex-1 text-center text-sm-start">
                                  <h3 className="mb-3 fw-bolder text-gradient-orange-1">
                                    Stitching
                                  </h3>
                                  <p className="mb-0 text-1200">
                                    Our well trained Karigars assisted by Designer and Master stitch the clothes with precise measurements and Designing Assistance.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-9 col-xxl-8 my-6">
                              <div className="d-sm-flex flex-center">
                                <img
                                  className="order-0 pe-4"
                                  src="assets/img/services/QualityStitching.png"
                                  alt="Quality Stitching with Perfect Fittings"
                                />
                                <div className="flex-1 text-center text-sm-start">
                                  <h3 className="mb-3 fw-bolder text-gradient-pink-1">
                                    Quality Checks
                                  </h3>
                                  <p className="mb-0 text-1200">
                                    After Stitching the Garments, Every stitched
                                    apparrel goes through the process of Quality
                                    Checks and Re-Measurements
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-9 col-xxl-8 my-6">
                              <div className="d-sm-flex flex-center">
                                <img
                                  className="order-1 pe-4"
                                  src="assets/img/services/TailorWithDelivery.png"
                                  alt="Home Pickup and Delivery in Noida"
                                />
                                <div className="flex-1 text-center text-sm-start">
                                  <h3 className="mb-3 fw-bolder text-gradient-blue">
                                    Delivery
                                  </h3>
                                  <p className="mb-0 text-1200">
                                    Once Finalized and Approved, We deliver Back
                                    your stitched Garments at your doorsteps
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-9 col-xxl-8 my-6">
                              <div className="d-sm-flex flex-center">
                                <img
                                  className="order-0 pe-4"
                                  src="assets/img/services/alterations.png"
                                  alt="Alterations of clothes in Noida"
                                />
                                <div className="flex-1 text-center text-sm-start">
                                  <h3 className="mb-3 fw-bolder text-gradient-orange-1">
                                    Alterations
                                  </h3>
                                  <p className="mb-0 text-1200">
                                    At the time of Delivery, You can check
                                    fittings and other Designings mentioned. We take care of the alterations if required.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container-fluid my-5">
                      <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div
                            className="swiper-container theme-slider gallerySwiper"
                            data-swiper='{"autoplay":{"delay":3000},"loop":true,"slideToClickedSlide":true,"effect":"fade","pagination":{"type":"bullets","clickable":true}}'
                          >
                            <div className="swiper-wrapper h-100">
                              <div className="swiper-slide h-100">
                                <div className="card h-100">
                                  <img
                                    className="img-fluid"
                                    src="assets/img/gallery/ProcessOfStitching.png"
                                    alt="Stitching in Noida by Tailor process"
                                  />
                                  <div className="card-body h-100 px-2">
                                    <h3 className="my-3 text-center">
                                      What is the process of placing stitching Order?
                                    </h3>
                                    <p className="mb-0">
                                      Welcome to Designer Karigar, where we prioritize convenience and craftsmanship in every stitch. Here's how our seamless stitching process works:
                                      <h5 className="my-3">Submit Your Requirement:</h5><p> <Link to='/contact-us'>Share</Link> your stitching needs with us, whether it's altering an existing garment or stitching a new one from scratch.</p>
                                      <h5 className="my-3">Designer Visit in your Home Comfort:</h5><p> Once we understand your requirements, we schedule a Designer visit to your location at your convenience. Our Designer will take precise measurements and discuss your preferences in detail.</p>
                                      <h5 className="my-3">Material Collection & Measurements:</h5><p> Don't worry about sourcing fabric! Our Designer will pick up your cloth material with all detailing during the visit to ensure a hassle-free experience.</p>
                                      <h5 className="my-3">Stitching the Garment:</h5><p> Our skilled Masters and Karigars get to work, meticulously stitching your garment with precision and attention to detail.</p>
                                      <h5 className="my-3">Quality Check:</h5><p> Before delivery, Our Designers conduct thorough quality checks to ensure that every stitch meets our high standards of excellence.</p>
                                      <h5 className="my-3">Delivery & Trial Fittings:</h5><p> Sit back and relax as we deliver your beautifully stitched clothes right to your doorstep within 5-7 days from pickup.</p>
                                      <h5 className="my-3">Alterations or Changes:</h5><p> We do take care of your loose-tight issues or changes as per the needs*</p>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-7 flex-center">
                      <div className="col-xl-9">
                        <div className="row">
                          <h2 className="text-center mb-6">
                            <span className="fw-medium">Our </span>Stitching Services
                            are available in{" "}
                          </h2>
                          <div className="col-6 col-sm-4 text-center mb-4">
                            <img
                              className="img-fluid"
                              src="assets/img/brands/Noida-Greater-Noida-West.png"
                              alt="Stitching in Noida, Noida externsion"
                            />
                          </div>
                          <div className="col-6 col-sm-4 text-center mb-4">
                            <img
                              className="img-fluid"
                              src="assets/img/brands/SouthDelhi.png"
                              alt="Stitching in South Delhi"
                            />
                          </div>
                          <div className="col-6 col-sm-4 text-center mb-4">
                            <img
                              className="img-fluid"
                              src="assets/img/brands/Gurugram.png"
                              alt="Stitching tailor in Gurugram"
                            />
                          </div>
                          <div className="col-6 col-sm-4 text-center mb-4">
                            <img
                              className="img-fluid"
                              src="assets/img/brands/comingsoon.png"
                              alt="Stitching upcoming places"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row justify-content-center pt-3d">
                      <div className="col-lg-8 col-xl-7 mb-n5 mb-md-n8 mb-lg-n6 mb-xl-n9 mt-8">
                        <div className="card bg-backdrop">
                          <div className="card-body p-2 px-lg-2 px-xl-5">
                            <div className="py-5 text-center">
                              <h2 className="fs-1 fs-xxl-2 fw-bolder">Book</h2>
                              <p className="mb-4 text-900">
                                Your Stitching Appointment Now!!
                              </p>
                              <Link to='../contact-us'
                                className="btn hover-top btn-boots-purple"
                              >
                                Book
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-10">
                        <img
                          className="w-100"
                          src="assets/img/cta/cta-services.png"
                          alt="Process of Stitching from Tailor"
                          style={{ borderRadius: "1rem" }}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center my-3">
                      <div className="col-12 col-lg-10">
                        <div className="card bg-soft-danger">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-lg-6 text-center text-lg-start">
                                <p className="fs--1 my-2 fw-bold text-gradient-danger-soft">
                                  All rights Reserved &copy; AGA Designer Karigar pvt ltd. , 2024
                                </p>
                              </div>
                              <div className="col-lg-6 d-lg-flex align-self-center justify-content-end text-center">
                                <p className="fs--1 mb-0 text-gradient-danger-soft">
                                  Made with{" "}
                                  <span className="fas fa-heart mx-1 text-danger"></span>
                                  by
                                  <a href="https://www.designerkarigar.com/">
                                    {" "}
                                    Designer Karigar
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3 col-12 position-absolute position-lg-relative ps-lg-0">
                <div
                  className="sticky-top py-4 sticky-area"
                  data-sidebar-close="sidebar-close"
                >
                  <a href="/"><div className="btn-close-boots-container times">
                    <div className="btn-close-boots"></div>
                  </div></a>
                  <div
                    className="bg-holder sidebar-rounded"
                    style={{
                      backgroundImage: "url(assets/img/sidebars/services.png)",
                    }}
                  ></div>

                  <div className="position-relative d-lg-flex flex-column justify-content-end align-items-end h-100 px-lg-4 px-xxl-5">
                    <h1 className="text-white text-vertical px-5 px-lg-0 opacity-50 fs-xl-3 fs-xxl-4">
                      Stitching Services
                    </h1>
                    <img
                      className="d-none d-lg-block line-icons mt-5"
                      src="assets/img/lineicons/Stitching-Process-bolt.png"
                      alt="Tailor stitching at your doorstep"
                    />
                    <hr className="my-4 w-100 d-none d-lg-block opacity-25" />
                    <div
                      className="flex-between-center d-none d-lg-flex w-100 opacity-75"
                      data-sidebar-link="page-link"
                    >
                      <Link
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        to="/about-us"
                      >
                        {" "}
                        <i className="fas fa-chevron-left me-lg-2 me-xl-2 me-xxl-3"></i>
                        <span className="text-capitalize">about</span>
                      </Link>
                      <Link
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        to="/contact-us"
                      >
                        <span className="text-capitalize">contact</span>
                        <i className="fas fa-chevron-right ms-lg-2 ms-xl-2 ms-xxl-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ServicesComponent;
