import React, { useEffect, useMemo, useState } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebase";
import DocumentMeta from 'react-document-meta';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
} from "@mui/material";
import { Tabs } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../src/styles.css";

const CatalogueComponent = () => {
  const meta = {
    title: 'Suits Designs | Indowestern Designs',
    description: 'Explore 1000 of Designs',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'Suits Designs, Indo Western Dress Designs, Mother Daughter Duo Dresses'
      }
    }
  }
  const [categories, setCategories] = useState();
  const [categoryItems, setCategoryItems] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalProductDetails, setModalProductDetails] = useState({});

  const [tabValue, setTabValue] = useState();

  const postCategoriesRef = collection(db, "customCategory");

  let { tabName } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (categories) {
      const tabNameArray = categories.map((obj) => obj?.categoryName);
      const eleIndex = tabNameArray.indexOf(decodeURIComponent(tabName));
      if (eleIndex !== -1) {
        setTabValue(eleIndex);
      } else {
        setTabValue(0);
        navigate(
          `/Catalogue/${encodeURIComponent(categories[0].categoryName)}`
        );
      }
    }
  }, [categories]);

  const getCategories = async () => {
    const data = await getDocs(postCategoriesRef);
    const finalCategories = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setCategories(finalCategories);

    const catItemsArr = [];
    await Promise.all(
      finalCategories.map(async (obj) => {
        const id = obj?.id;
        const postCategoriesContentRef = collection(
          db,
          `customCategory/${id}/category_item`
        );
        const data = await getDocs(postCategoriesContentRef);
        const finalCatItems = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        catItemsArr.push(finalCatItems);
      })
    );
    setCategoryItems(catItemsArr);
  };

  const handelTabValueChange = (event, newValue) => {
    setTabValue(newValue);
    navigate(
      `/Catalogue/${encodeURIComponent(categories[newValue].categoryName)}`
    );
  };

  const handelModalClose = () => {
    setIsModalVisible(false);
  };
  const handelModalOpen = (selectedProduct) => {
    setModalProductDetails(selectedProduct);
    setIsModalVisible(true);
  };

  const onModalBookClick = () => { };

  return useMemo(
    () => (
      <>
        <DocumentMeta {...meta} />
        <div>
          <section className="p-0 page" id="Catalogue">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8 col-xl-9 order-1 order-lg-0 pt-10 pt-lg-8 pt-xl-7 pt-xxl-8 pb-0">
                  <div className="row flex-center">
                    <div className="col-lg-10 col-xl-10">
                      <div className="text-center mb-5 mb-lg-6">
                        <h2>
                          <span className="fw-medium">Our</span> Latest
                          Designs
                        </h2>
                      </div>
                      <div>
                        {tabValue !== undefined && (
                          <Tabs
                            value={tabValue}
                            onChange={handelTabValueChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                          >
                            {categories &&
                              categories.map((tab, index) => (
                                <Tab key={index} label={tab?.categoryName} />
                              ))}
                          </Tabs>
                        )}
                        <div
                          className="container-fluid"
                          style={{ marginTop: "20px" }}
                        >
                          <div className="row" style={{ rowGap: "40px" }}>
                            {categoryItems &&
                              categoryItems[tabValue]?.map((child, ind) => (
                                <div
                                  className="col-lg-6 col-xl-4 col-md-6 col-sm-12"
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    style={{ height: "350px", width: "95%" }}
                                    className="market-lines"
                                    src={child?.item_photo}
                                    onClick={() => handelModalOpen(child)}
                                    alt={child?.item_name}
                                  />
                                  <div className="product-details-container">
                                    <h6 style={{ color: "#1976d2" }}>
                                      {child?.item_name.toLocaleUpperCase()}
                                    </h6>
                                    <h5
                                      style={{
                                        fontSize: "16px",
                                        color: "#1976d2",
                                      }}
                                    >
                                      {child?.item_price}/-
                                    </h5>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row justify-content-center pt-3d">
                        <div className="col-lg-8 col-xl-7 mb-n5 mb-md-n8 mb-lg-n6 mb-xl-n9 mt-8">
                          <div className="card bg-backdrop">
                            <div className="card-body p-2 px-lg-2 px-xl-5">
                              <div className="py-5 text-center">
                                <h2 className="fs-1 fs-xxl-2 fw-bolder">
                                  Book
                                </h2>
                                <p className="mb-4 text-900">
                                  Your Stitching Appointment Now!!
                                </p>
                                <a
                                  className="btn hover-top btn-boots-warning"
                                  href="/contact-us"
                                >
                                  BOOK
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-10">
                          <img
                            className="w-100"
                            src="/assets/img/cta/cta-portfolio.png"
                            alt="Tailor in Noida"
                            style={{ borderRadius: "1rem" }}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center my-3">
                        <div className="col-12 col-lg-10">
                          <div className="card bg-soft-pink">
                            <div className="card-body py-3">
                              <div className="row">
                                <div className="col-lg-6 text-center text-lg-start">
                                  <p className="fs--1 my-2 fw-bold text-gradient-pink-soft">
                                    All rights Reserved &copy; AGA Designer Karigar PVT LTD.,
                                    2024
                                  </p>
                                </div>
                                <div className="col-lg-6 d-lg-flex align-self-center justify-content-end text-center">
                                  <p className="fs--1 mb-0 text-gradient-pink-soft">
                                    Made with{" "}
                                    <span className="fas fa-heart mx-1 text-secondary"></span>
                                    by
                                    <a href="https://www.designerkarigar.com/">
                                      {" "}
                                      Anjali
                                    </a>
                                  </p>
                                  <img
                                    src="/assets/img/logos/diamond.png"
                                    alt="Designer Karigar Book Your Order logo"
                                    className="very-small-logo"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-3 col-12 position-absolute position-lg-relative ps-lg-0">
                  <div
                    className="sticky-top py-4 sticky-area"
                    data-sidebar-close="sidebar-close"
                  >
                    <a href="/"><div className="btn-close-boots-container times">
                      <div className="btn-close-boots"></div>
                    </div></a>
                    <div
                      className="bg-holder sidebar-rounded"
                      style={{
                        backgroundImage:
                          "url(/assets/img/sidebars/portfolio.png)",
                      }}
                    ></div>

                    <div className="position-relative d-lg-flex flex-column justify-content-end align-items-end h-100 px-lg-4 px-xxl-5">
                      <h1 className="text-white text-vertical px-5 px-lg-0 opacity-50 fs-xl-3 fs-xxl-4">
                        Catalogue
                      </h1>
                      <img
                        className="d-none d-lg-block line-icons mt-5"
                        src="/assets/img/lineicons/catalogue.png"
                        alt="Latest Designs for Suits"
                      />
                      <hr className="my-4 w-100 d-none d-lg-block opacity-25" />
                      <div
                        className="flex-between-center d-none d-lg-flex w-100 opacity-75"
                        data-sidebar-link="page-link"
                      >
                        <Link
                          className="sidebar-nav btn btn-link text-decoration-none px-1"
                          to="/contact-us"
                        >
                          {" "}
                          <i className="fas fa-chevron-left me-lg-2 me-xl-2 me-xxl-3"></i>
                          <span className="text-capitalize">Book</span>
                        </Link>
                        <Link
                          className="sidebar-nav btn btn-link text-decoration-none px-1"
                          to="/blog"
                        >
                          <span className="text-capitalize">Blogs</span>
                          <i className="fas fa-chevron-right ms-lg-2 ms-xl-2 ms-xxl-4"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Dialog
          open={isModalVisible}
          onClose={handelModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div style={{ borderBottom: "1px solid lightgrey" }}>
              <h3>Product Details</h3>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="d-flex justify-content-center flex-column">
              <h5 className="text-center">
                {modalProductDetails?.item_name?.toLocaleUpperCase()}
              </h5>
              <h4 className="text-center mt-3">
                {modalProductDetails?.item_price}/- Rs Only
              </h4>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handelModalClose}>
              close
            </Button>
            <Link to="/contact-us">
              <Button variant="contained">Book Now</Button>
            </Link>
          </DialogActions>
        </Dialog>
      </>
    ),
    [categories, categoryItems, tabValue, isModalVisible, modalProductDetails]
  );
};

export default CatalogueComponent;
