import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUsComponent from "./pages/AboutUsComponent";
import HomePageComponent from "./pages/HomePageComponent";
import ServicesComponent from "./pages/ServicesComponent";
import ContactUsComponent from "./pages/ContactUsComponent";
import GalleryComponent from "./pages/GalleryComponent";
import BlogComponent from "./pages/BlogComponent";
import BlogDetailsComponent from "./components/BlogDetailsComponent";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import LoginComponent from "./pages/LoginComponent";
import RentalFormComponent from "./pages/RentalFormComponent";
import RentalListComponent from "./pages/RentalListComponent";
import CatalogueComponent from "./pages/CatalogueComponent";
import RentalHomePage from "./pages/RentalHomePageComponent";
import NoInternetPage from "./pages/SinglePages/NoInternetPage";
import { PageNotFound } from "./pages/SinglePages/PageNotFound";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePageComponent />} />
          <Route path="/home" element={<HomePageComponent />} />
          <Route path="/about-us" element={<AboutUsComponent />} />
          <Route path="/services" element={<ServicesComponent />} />
          <Route path="/contact-us" element={<ContactUsComponent />} />
          <Route path="/gallery" element={<GalleryComponent />} />
          <Route path="/blog" element={<BlogComponent />} />
          <Route path="/Catalogue" element={<CatalogueComponent />} />
          <Route path="/Catalogue/:tabName" element={<CatalogueComponent />} />
          <Route path="/blog/:blogId" element={<BlogDetailsComponent />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/rental/add" element={<RentalFormComponent />} />
          <Route path="/rental/:id" element={<RentalFormComponent />} />
          <Route path="/rental-list" element={<RentalListComponent />} />
          <Route path="/rental" element={<RentalHomePage />} />
          <Route path="/rental" element={<RentalHomePage />} />
          <Route path="/no_internet" element={<NoInternetPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
