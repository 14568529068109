import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { auth } from "../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import "../../src/styles.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const LoginComponent = () => {
  const [formData, setFormData] = useState({
    phoneNumber: "",
  });
  const [otp, setOtp] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState(false);
  const [captchaVerificationResp, setCaptchaVerificationResp] = useState(null);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    type: "",
    message: "",
  });
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [searchParams] = useSearchParams();
  const navigateToRoute = searchParams.get('navigate');

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    console.log(navigateToRoute)
  }, [])

  const onSendOtpClick = async (event) => {
    setIsLoginDisabled(true);
    try {
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
        callback: () => {
          window.recaptchaVerifier.recaptcha.reset();
        },
        "expired-callback": () => {
          window.recaptchaVerifier.recaptcha.reset();
        },
        "error-callback": () => {
          window.recaptchaVerifier.recaptcha.reset();
        },
      });
      window.recaptchaVerifier = recaptcha;

      const confirmation = await signInWithPhoneNumber(
        auth,
        `+91-${formData.phoneNumber}`,
        recaptcha
      );
      setCaptchaVerificationResp(confirmation);
      handelModalOpen();
    } catch (err) {

      handelSnackbarOpen("error", err.message);

      navigate('/home');
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  };

  const onVerifyOtpClick = async () => {
    try {
      const resp = await captchaVerificationResp.confirm(otp);
      localStorage.setItem('loggedInUser', JSON.stringify(
        {
          mobileNo: formData?.phoneNumber
        }
      ))
      handelModalClose();
      setFormData({
        phoneNumber: "",
      });
      handelSnackbarOpen("success", "Login Successfull");
      setTimeout(() => {
        if (navigateToRoute) {
          navigate(`/${navigateToRoute}`);
        } else {
          window.location.href = '/home'
        }
      }, 2000)
    } catch (err) {
      setFormData({
        phoneNumber: "",
      });
      handelSnackbarOpen("error", err?.message || "Error");
      // navigate('/home');
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  };

  const onFieldValueChange = (e, key) => {
    const data = { ...formData };
    data[key] = e.target.value;
    setFormData(data);
  };

  const handelModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setIsModalVisible(false);
  };

  const handelModalCloseCancel = (event, reason) => {
    setIsModalVisible(false);
    window.location.href = '/login'
  };

  const handelModalOpen = () => {
    setIsModalVisible(true);
  };

  const handelSnackbarClose = () => {
    setSnackbar({
      isOpen: false,
      type: "",
      message: "",
    });
  };

  const handelSnackbarOpen = (type, message) => {
    setSnackbar({
      isOpen: true,
      type: type,
      message: message,
    });
  };

  return (
    <>
      <section className="page p-0 " id="login">
        <div ref={inputRef} className="container-fluid" >
          <div className="top-0 w-100">
            <div className="row">
              <div className="col-lg-8 col-xl-9 order-1 order-lg-0 pt-6 pt-lg-0">
                <section className="pt-8 pt-xl-7 pt-xxl-8 pb-0">
                  <div
                    className="bg-holder bg-size"
                    style={{
                      backgroundImage: "url(assets/img/contact-bg.png)",
                      backgroundPosition: "left center",
                      backgroundSize: "auto",
                    }}
                  ></div>

                  <div className="container-fluid">
                    <div className="row justify-content-center pb-5">
                      <div className="col-sm-9 col-lg-6">
                        <div
                          className="text-center mb-5 mb-lg-6"
                          style={{
                            border: "2px solid #fcbf09",
                            padding: "50px",
                            borderRadius: "20px",
                          }}
                        >
                          <h2 style={{ color: "#fcbf09" }}>
                            <span className="fw-medium"></span>Login
                          </h2>
                          <h5 style={{ marginTop: "30px" }}>
                            Enter Mobile Number
                          </h5>

                          <TextField
                            inputlabelprops={{ shrink: true }}
                            variant="outlined"
                            id="inputName"
                            type="number"
                            name="phoneNumber"
                            placeholder="9898989898"
                            value={formData.phoneNumber}
                            onChange={(event) => {
                              onFieldValueChange(event, "phoneNumber");
                            }}
                            className="contact-form"
                          />

                          <div
                            id="recaptcha-container"
                            style={{ marginTop: "10px" }}
                          ></div>

                          <Button
                            style={{
                              marginTop: "30px",
                              width: "50%",
                              backgroundColor: "#fcbf09",
                            }}
                            variant="contained"
                            onClick={onSendOtpClick}
                            disabled={!formData?.phoneNumber || isLoginDisabled}
                          >
                            {
                              isLoginDisabled && (
                                <CircularProgress style={{ height: '20px', width: '20px', display: 'flex', justifyContent: 'center', position: 'absolute' }} />
                              )
                            }
                            Login
                          </Button>
                        </div>
                        <div className="text-center mb-5 mb-lg-6">
                          <h2 className="mb-5">Follow </h2>
                          <ul className="list-unstyled list-inline">
                            <li className="list-inline-item mx-2">
                              <a href="https://www.facebook.com/designerkarigar/">
                                <img
                                  src="assets/img/icons/facebook.png"
                                  alt="Designer Karigar facebook page"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item mx-2">
                              <a href="https://www.instagram.com/designerkarigar/?hl=en">
                                <img
                                  src="assets/img/icons/twitter.png"
                                  alt="Designer Karigar Instagram Page"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item mx-2">
                              <a href="https://www.youtube.com/channel/UCMQayw0F35vuPNNdp0FDXFQ">
                                <img
                                  src="assets/img/icons/youtube.png"
                                  alt="Designer Karigar YouTube Channel"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item mx-2">
                              <a href="https://in.linkedin.com/company/designer-karigar">
                                <img
                                  src="assets/img/icons/linkdin.png"
                                  alt="Designer Karigar Linkedin Page"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item mx-2">
                              <a href="https://play.google.com/store/apps/details?id=com.mycompany.designerkarigar&hl=en_US">
                                <img
                                  src="assets/img/icons/google-plus.png"
                                  alt="Designer Karigar Google Playstore"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center my-3">
                      <div className="col-12 col-lg-10">
                        <div className="card bg-soft-orange-1">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-lg-6 text-center text-lg-start">
                                <p className="fs--1 my-2 fw-bold text-gradient-orange-soft-1">
                                  All rights Reserved &copy; AGA Designer
                                  Karigar pvt ltd. , 2024
                                </p>
                              </div>
                              <div className="col-lg-6 d-lg-flex align-self-center justify-content-end text-center">
                                <p className="fs--1 mb-0 text-gradient-orange-soft-1">
                                  Made with{" "}
                                  <span className="fas fa-heart mx-1 text-warning"></span>
                                  by
                                  <a href="https://www.designerkarigar.com/">
                                    {" "}
                                    Designer Karigar
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-4 col-xl-3 col-12 position-absolute position-lg-relative ps-lg-0">
                <div
                  className="sticky-top py-4 sticky-area"
                  data-sidebar-close="sidebar-close"
                >
                  <a href="/">
                    <div className="btn-close-boots-container times">
                      <div className="btn-close-boots"></div>
                    </div>
                  </a>
                  <div
                    className="bg-holder sidebar-rounded"
                    style={{
                      backgroundImage: "url(assets/img/sidebars/contact.png)",
                    }}
                  ></div>

                  <div className="position-relative d-lg-flex flex-column justify-content-end align-items-end h-100 px-lg-4 px-xxl-5">
                    <h1 className="text-white text-vertical px-5 px-lg-0 opacity-50 fs-xl-3 fs-xxl-4">
                      Login
                    </h1>
                    <img
                      className="d-none d-lg-block line-icons mt-5"
                      src="assets/img/lineicons/map.png"
                      alt="icon"
                    />
                    <hr className="my-4 w-100 d-none d-lg-block opacity-25" />
                    <div
                      className="flex-between-center d-none d-lg-flex w-100 opacity-75"
                      data-sidebar-link="page-link"
                    >
                      <Link
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        to="/"
                      >
                        {" "}
                        <i className="fas fa-chevron-left me-lg-2 me-xl-2 me-xxl-3"></i>
                        <span className="text-capitalize">Home</span>
                      </Link>
                      <Link
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        to="/rental"
                      >
                        <span className="text-capitalize">Rentals</span>
                        <i className="fas fa-chevron-right ms-lg-2 ms-xl-2 ms-xxl-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Dialog
        open={isModalVisible}
        onClose={handelModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{ borderBottom: "1px solid lightgrey" }}>
            <h4 style={{ color: "#f4763b" }}>Verify OTP</h4>
          </div>
        </DialogTitle>
        <DialogContent>
          <div
            style={{ width: "350x", height: "80px" }}
            className="d-flex justify-content-center flex-column"
          >
            <input
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              placeholder="Enter OTP"
              type="number"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handelModalCloseCancel}>
            close
          </Button>
          <Button variant="contained" onClick={onVerifyOtpClick}>
            Verify
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.isOpen}
        autoHideDuration={8000}
        onClose={handelSnackbarClose}
      >
        <Alert
          onClose={handelSnackbarClose}
          severity={snackbar.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoginComponent;
