import { Alert, Button, Dialog, DialogActions, DialogTitle, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const BannerComponent = (props) => {
    const [userName, setUserName] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({
        isOpen: false,
        type: "",
        message: "",
    });

    useEffect(() => {
        const userInfo = localStorage.getItem('loggedInUser');
        if (JSON.parse(userInfo)) {
            setUserName(JSON.parse(userInfo)?.mobileNo)
        }
    }, [])

    const onLogoutClick = () => {
        setIsDialogOpen(true);
    }

    const onLogoutConfirm = () => {
        localStorage.removeItem('loggedInUser');
        setUserName(null);
        setIsDialogOpen(false);
        handelSnackbarOpen('success', 'User Logged Out Successfully!')
    }

    const onLogoutReject = () => {
        setIsDialogOpen(false);
    }

    const handelSnackbarClose = () => {
        setSnackbar({
            isOpen: false,
            type: "",
            message: "",
        });
    };

    const handelSnackbarOpen = (type, message) => {
        setSnackbar({
            isOpen: true,
            type: type,
            message: message,
        });
    };

    return (
        <>
            <div className={`offcanvas offcanvas-start home-content ${props.show}`} id="offcanvasStart" tabIndex="-1" data-bs-backdrop="false" data-bs-scroll="true" data-base-content="home">
                <div className="offcanvas-body d-flex flex-column flex-center h-sm-25vh h-lg-50vh" style={{ overflow: 'hidden' }}>
                    <div className="row" style={{ width: '100%' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            {userName && (
                                <p style={{ marginBottom: '0px', color: '#e12f72' }}><span style={{ fontWeight: 'bold' }}>Welcome:</span> {userName}</p>
                            )}
                            {!userName && (
                                <p style={{ marginBottom: '0px' }}></p>
                            )}
                            {
                                userName && (
                                    <Button variant='contained' onClick={onLogoutClick} style={{ marginBottom: '0px' }}>Logout</Button>
                                )
                            }
                            {
                                !userName && (
                                    <Link to='/login'>
                                        <Button variant='contained' style={{ marginBottom: '0px' }}>Login</Button>
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-11 col-sm-9 col-lg-10 col-xxl-12 text-center"><img className="img-fluid px-6 px-xl-6 px-xxl-100 w-lg-5 w-sm-75" src="assets/img/logos/logo-6-3.png" alt="Stitching Services near me" />
                            <h1 className="fw-light mt-2 fs-2 fs-xl-4"> <span className="fw-bolder text-gradient-blue" style={{ "letterSpacing": "-0.125rem" }}>Stitching Services </span><span className="fw-bolder text-gradient-orange-1">@ your doostep.</span></h1>
                            <p className="mt-3 fs-0 d-none d-lg-block">Customized stylist visits to your home for Pick-up, Measurement and Designs Finalisation<br className="d-none d-xxl-block" />Delivery in 5-7 days</p>
                            <div className="mt-lg-4"><a className="text-900 fw-bold px-2" href="tel:8826738307">CALL: +91-8826738307</a><br className="d-block d-sm-none" /><a className="text-primary fw-bold px-2" href="https://wa.me/918588838307" target="_blank">Chat Now <span className="fas fa-chevron-right" data-fa-transform="shrink-4 down-1.5"></span></a><a className="fs-1 fs-l-2 fw-bolder text-gradient-orange-1" href="/contact-us">Book Now <span className="fas fa-chevron-right" data-fa-transform="shrink-4 down-1.5"></span></a></div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                open={isDialogOpen}
                onClose={onLogoutReject}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are You sure you want to logout?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={onLogoutReject}>Cancel</Button>
                    <Button onClick={onLogoutConfirm} autoFocus>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={8000}
                onClose={handelSnackbarClose}
            >
                <Alert
                    onClose={handelSnackbarClose}
                    severity={snackbar.type}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbar?.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default BannerComponent;

