import { addDoc, collection } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { app, auth, db } from "../firebase";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import "../../src/styles.css";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const RentalHomePage = () => {
  return (
    <div>
      <section className="page p-0 " id="contact">
        <div className="container-fluid">
          <div className="top-0 w-100">
            <div className="row">
              <div className="col-lg-8 col-xl-9 order-1 order-lg-0 pt-6 pt-lg-0">
                <div
                  style={{
                    display: "flex",
                    flexWrap: 'wrap',
                    flexDirection: "column",
                    justifyContent: "space-between",
                    paddingTop: "20px",
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Link to="/rental-list">
                      <Button startIcon={<AddIcon />} variant="contained">
                        My Rentals
                      </Button>
                    </Link>
                  </div>
                  <div style={{ height: "83vh" }}></div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Link to="/rental/add">
                      <Button startIcon={<AddIcon />} variant="contained">
                        Rent My Clothes
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3 col-12 position-absolute position-lg-relative ps-lg-0">
                <div
                  className="sticky-top py-4 sticky-area"
                  data-sidebar-close="sidebar-close"
                >
                  <a href="/">
                    <div className="btn-close-boots-container times">
                      <div className="btn-close-boots"></div>
                    </div>
                  </a>
                  <div
                    className="bg-holder sidebar-rounded"
                    style={{
                      backgroundImage: "url(assets/img/sidebars/contact.png)",
                      marginTop: "15px",
                    }}
                  ></div>

                  <div className="position-relative d-lg-flex flex-column justify-content-end align-items-end h-100 px-lg-4 px-xxl-5">
                    <h1 className="text-white text-vertical px-5 px-lg-0 opacity-50 fs-xl-3 fs-xxl-4">
                      Rentals
                    </h1>
                    <img
                      className="d-none d-lg-block line-icons mt-5"
                      src="assets/img/lineicons/map.png"
                      alt="icon"
                    />
                    <hr className="my-4 w-100 d-none d-lg-block opacity-25" />
                    <div
                      className="flex-between-center d-none d-lg-flex w-100 opacity-75"
                      data-sidebar-link="page-link"
                    >
                      <a
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        href="/services"
                      >
                        {" "}
                        <i className="fas fa-chevron-left me-lg-2 me-xl-2 me-xxl-3"></i>
                        <span className="text-capitalize">Services</span>
                      </a>
                      <a
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        href="/catalogue"
                      >
                        <span className="text-capitalize">Catalogue</span>
                        <i className="fas fa-chevron-right ms-lg-2 ms-xl-2 ms-xxl-4"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RentalHomePage;
