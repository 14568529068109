import React from "react";
import DocumentMeta from 'react-document-meta';
import { Link } from "react-router-dom";

const GalleryComponent = () => {
  const meta = {
    title: 'Details of Boutique and unique styling',
    description: 'Designs and various stitching details',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'Detailing of Designs, Boutique in Noida, Boutique in Gurugram, Tailor near me'
      }
    }
  }
  return (
    <>
      <DocumentMeta {...meta} />
      <div>
        <section className="p-0 page" id="gallery">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8 col-xl-9 order-1 order-lg-0 pt-10 pt-xl-7 pt-xxl-8 pb-0">
                <div className="row min-vh-25 flex-center">
                  <div className="col-lg-10">
                    <div className="text-center mb-5 mb-lg-6">
                      <h2>
                        <span className="fw-medium"> Designer Karigar </span><p>Stitching Boutique in Noida & Gurugram</p>                    </h2>
                    </div>


                    <div className="row py-7 justify-content-center">
                      <img
                        className="img-fluid"
                        src="assets/img/gallery/StitchingAtDoorstepDesigner.png"
                        alt="Stitching At Doorstep Designer"
                      />

                      <h1 className="mb-4 my-3 text-center">
                        <span className="fw-medium">Basic </span>Stitching Prices
                      </h1>
                      <div className="col-sm-6 col-xl-4 pt-4 px-md-2">
                        <div
                          className="card card-span h-100 bg-white"
                          style={{ borderRadius: "1rem" }}
                        >
                          <div className="card-body d-flex flex-column justify-content-around px-xl-4 px-xxl-5">
                            <div className="text-center">
                              <h2 className="fs-lg-2 fx-xxl-4 text-800 my-3">
                                Suits
                              </h2>
                              <h2 className="mb-3 fs-lg-2 fs-xxl-4 text-gradient-blue">
                                Starts{" "}
                                <span className="text-gradient-gray fs-1">
                                  800/-
                                </span>
                              </h2>
                            </div>
                            <ul className="list-unstyled my-4 ps-5 ps-lg-0 ps-xl-2 ps-xxl-5">
                              <li className="mb-3">
                                <span className="me-2">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                Half Lining 1150/-
                              </li>
                              <li className="mb-3">
                                <span className="me-2">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                Full Lining 1400/-
                              </li>
                              <li className="mb-3">
                                <span className="me-2">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                Designer Assisst
                              </li>
                              <li className="mb-3">
                                <span className="me-2">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                Quality Checks
                              </li>
                              <li className="mb-3">
                                <span className="me-2">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                Free Pickup Delivery
                              </li>
                            </ul>
                            <a href='/contact-us'>
                              <div className="text-center">
                                <div className="d-grid">
                                  <button
                                    className="btn btn-klean-outline border-0"
                                    type="submit"
                                  >
                                    {" "}
                                    <span className="text-gradient-blue">
                                      Stitch Now
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4 pt-4 px-md-2">
                        <div
                          className="card card-span h-100 bg-pricing-gradient"
                          style={{ borderRadius: "1rem" }}
                        >
                          <div className="card-body d-flex flex-column justify-content-around px-xl-4 px-xxl-5">
                            <div className="text-center">
                              <h2 className="fs-lg-2 fx-xxl-4 text-800 my-3">
                                Lehengas
                              </h2>
                              <h2 className="mb-3 fs-lg-2 fs-xxl-4 text-gradient-pink-2">
                                Starts{" "}
                                <span className="text-gradient-gray fs-1">
                                  3500/-
                                </span>
                              </h2>
                            </div>
                            <ul className="list-unstyled my-4 ps-5 ps-lg-0 ps-xl-2 ps-xxl-5">
                              <li className="mb-3">
                                <span className="me-2">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                Free Pickup
                              </li>
                              <li className="mb-3">
                                <span className="me-2">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                Free Delivery
                              </li>
                              <li className="mb-3">
                                <span className="me-2">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                Designer Assisst
                              </li>
                              <li className="mb-3">
                                <span className="me-2">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                Quality Checks
                              </li>
                            </ul>
                            <a href='/contact-us'>
                              <div className="text-center">
                                <div className="d-grid">
                                  <button
                                    className="btn btn-pricing-danger border-0"
                                    type="submit"
                                  >
                                    Stitch Now
                                  </button>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4 pt-4 px-md-2">
                        <div
                          className="card card-span h-100 bg-white"
                          style={{ borderRadius: "1rem" }}
                        >
                          <div className="card-body d-flex flex-column justify-content-around px-xl-4 px-xxl-5">
                            <div className="text-center">
                              <h2 className="fs-lg-2 fx-xxl-4 text-800 my-3">
                                Blouses
                              </h2>
                              <h2 className="mb-3 fs-lg-2 fs-xxl-4 text-gradient-blue">
                                Starts{" "}
                                <span className="text-gradient-gray fs-1">
                                  650/-
                                </span>
                              </h2>
                            </div>
                            <ul className="list-unstyled my-4 ps-5 ps-lg-0 ps-xl-2 ps-xxl-5">
                              <li className="mb-3">
                                <span className="me-2">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                Pricess Cut 800/-
                              </li>
                              <li className="mb-3">
                                <span className="me-2">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                Belted Blouse 900/-
                              </li>
                              <li className="mb-3">
                                <span className="me-2">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                Lehenga Blouse 1500/-*
                              </li>
                              <li className="mb-3">
                                <span className="me-2">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                Padded Blouse 1150/-*
                              </li>
                            </ul>
                            <Link to='../contact-us'>
                              <div className="text-center">
                                <div className="d-grid">
                                  <button
                                    className="btn btn-klean-outline border-0"
                                    type="submit"
                                  >
                                    {" "}
                                    <span className="text-gradient-blue">
                                      Stitch Now
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <section className="py-3" id="swiper">
                      <div className="container-fluid">
                        <div className="row justify-content-center">
                          <div className="col-lg-10 col-xl-8">
                            <div
                              className="swiper-container theme-slider gallerySwiper"
                              data-swiper='{"autoplay":{"delay":3000},"loop":true,"slideToClickedSlide":true,"effect":"fade","pagination":{"type":"bullets","clickable":true}}'
                            >
                              <div className="swiper-pagination"> </div>
                            </div>
                            <div className="row justify-content-between">
                              <div className="col-sm-6 my-sm-6 border-lg-end pe-sm-5">
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/SuitsandDesigns.png"
                                  alt="Suits Designs in Noida"
                                />
                                <h5 className="mb-2 mt-4">Suits Stitching & Designing</h5>
                                <p className="mb-5">
                                  At Designer Karigar, we specialize in the meticulous stitching and designing of ladies' suits, offering tailored solutions to suit every preference. Our expertise extends to three main types of suits: those without lining, providing a lightweight and airy option; suits with half lining, offering added structure and comfort with lining on top; and suits with full lining, ensuring a polished and refined look with lining on both top and bottom. Whether you prefer a minimalist aesthetic or desire a more structured silhouette, we tailor each suit to your unique specifications, ensuring the perfect fit and style for every occasion. Experience the epitome of elegance and sophistication with our bespoke ladies' suits stitching and designing services at Designer Karigar.
                                </p>
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/KurtaPajama.png"
                                  alt="Kurta Pajama Stitching Designs"
                                />
                                <h5 className="mb-2 mt-4">Kurta Pajama Sherwani Stitching</h5>
                                <p className="mb-5">
                                  At Designer Karigar, we understand the importance of comfort and style in traditional attire, which is why we offer expert Kurta Pajama stitching services tailored to your preferences. Whether you're attending a wedding, festival, or any special occasion, a well-fitted Kurta Pajama adds a touch of elegance to your ensemble. Our skilled designers ensure that every stitch is made with precision, creating outfits that not only look good but also feel comfortable to wear. From selecting the fabric to finalizing the design details, we work closely with you to bring your vision to life. With Designer Karigar, you can trust us to deliver high-quality Kurta Pajamas that reflect your unique style and personality, making you stand out on every occasion.
                                </p>
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/LehengaStitching.png"
                                  alt="Lehenga Stitching in Noida"
                                />
                                <h5 className="mb-2 mt-4">Lehenga Stitching</h5>
                                <p className="mb-5">
                                  At Designer Karigar, we take pride in our exquisite Lehenga stitching services, designed to make you look and feel stunning on your special day. Whether it's for a wedding, engagement, or any celebratory event, a beautifully crafted Lehenga adds grace and charm to your ensemble. Our skilled team of designers pays meticulous attention to detail, ensuring that every stitch is perfect and every embellishment is placed with care. From traditional to contemporary designs, we work closely with you to create a Lehenga that reflects your unique style and personality. With Designer Karigar, you can trust us to deliver a Lehenga that fits you like a dream and makes you the center of attention wherever you go. Let us bring your Lehenga dreams to life with our expert stitching services.
                                </p>
                              </div>
                              <div className="col-sm-6 my-sm-6 border-lg-start ps-sm-5">
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/GownsDesigns.png"
                                  alt="Gowns Stitching in Noida"
                                />
                                <h5 className="mb-2 mt-4">Gowns & Party Wear Dresses</h5>
                                <p className="mb-5">
                                  At Designer Karigar, we excel in creating elegant gowns and partywear dresses that make you shine at any occasion. Whether it's a cocktail party, a gala event, or a special evening out, our expert stitching services ensure you stand out with style and sophistication. Our team of skilled designers pays close attention to every detail, from the selection of luxurious fabrics to the placement of intricate embellishments, ensuring that your gown or partywear dress is nothing short of perfection. Whether you prefer classic silhouettes or modern designs, we work closely with you to bring your vision to life, creating a dress that perfectly complements your personality and enhances your beauty. With Designer Karigar, you can trust us to deliver a gown or partywear dress that makes you feel confident, glamorous, and ready to dazzle the crowd. Let us help you make a statement with our expertly crafted designs.
                                </p>
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/RecreateSarees.png"
                                  alt="Recreate your sarees in Noida"
                                />
                                <h5 className="mb-2 mt-4">Recreate your old Sarees</h5>
                                <p className="mb-5">
                                  At Designer Karigar, we specialize in transforming your old sarees into beautiful new apparel that you'll love to wear again and again. Whether it's a cherished family heirloom or a saree with sentimental value, our expert team of designers can breathe new life into it, creating stunning outfits that are both stylish and meaningful. From traditional to contemporary designs, we work closely with you to understand your preferences and create custom-made apparel that suits your taste and style. Whether you want to turn your old saree into a trendy dress, a chic blouse, or a stylish skirt, we have the skills and expertise to bring your vision to life. With Designer Karigar, you can trust us to preserve the essence of your old sarees while giving them a fresh and modern twist. Let us help you create beautiful new apparel from your old sarees and make memories that last a lifetime.
                                </p>
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/SareeBlouse.png"
                                  alt="Saree Blouses Designs"
                                />
                                <h5 className="mb-2 mt-4">Sarees & Blouse</h5>
                                <p className="mb-5">
                                  At Designer Karigar, we take pride in our craftsmanship and attention to detail, ensuring that each blouse is stitched with the utmost care and dedication to quality. Let us bring your vision to life with our bespoke blouse stitching services, tailored to your individual style and preferences.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className="py-3">
                      <div className="container-fluid">
                        <div className="row justify-content-center">
                          <div className="col-lg-10 col-xl-8">
                            <div
                              className="swiper-container theme-slider gallerySwiper"
                              data-swiper='{"autoplay":{"delay":3000},"loop":true,"slideToClickedSlide":true,"effect":"fade","pagination":{"type":"bullets","clickable":true}}'
                            >
                              <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                  <div className="card">
                                    {" "}
                                    <img
                                      className="img-fluid"
                                      src="assets/img/gallery/DrapeSarees.png"
                                      alt="Drape Sarees in Noida"
                                    />
                                    <div className="card-body px-2">
                                      <h5 className="my-3 text-center">
                                        Blouses & Sarees
                                      </h5>
                                      <p className="mb-0">
                                        At Designer Karigar, we specialize in crafting exquisite Indian wear blouses through our expert stitching services, ensuring that each piece is tailored to perfection according to your unique preferences and measurements. With a variety of styles to choose from, we cater to every occasion and individual taste.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide">
                                  <div className="card">
                                    <img
                                      className="img-fluid"
                                      src="assets/img/gallery/SimpleBlouse.png"
                                      alt="Simple Blouse Designs in Noida"
                                    />
                                    <div className="card-body px-2">
                                      <h5 className="my-3 text-center">
                                        Simple Blouses
                                      </h5>
                                      <p className="mb-0">
                                        Our skilled artisans meticulously stitch simple blouses with clean lines and minimalistic designs, allowing the beauty of your saree or lehenga to shine through effortlessly.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide">
                                  <div className="card">
                                    <img
                                      className="img-fluid"
                                      src="assets/img/gallery/PaddedBlouse.png"
                                      alt="Padded Blouse Designs in Noida"
                                    />
                                    <div className="card-body px-2">
                                      <h5 className="my-3 text-center">
                                        Padded Blouses
                                      </h5>
                                      <p className="mb-0">
                                        Offering both comfort and support, our padded blouses are carefully stitched to ensure a perfect fit and flattering silhouette, providing confidence and ease of wear.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="swiper-pagination"> </div>
                            </div>
                            <div className="row justify-content-between">
                              <div className="col-sm-6 my-sm-6 border-lg-end pe-sm-5">
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/LehengaBlouse.png"
                                  alt="Lehenga Blouse Designs in Noida"
                                />
                                <h5 className="mb-2 mt-4">Lehenga Blouses</h5>
                                <p className="mb-5">
                                  Tailored specifically to complement lehengas, our lehenga blouses feature intricate embellishments and detailed craftsmanship, enhancing the overall elegance of your ensemble.
                                </p>
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/sidezip.png"
                                  alt="Side Chain Blouse Designs"
                                />
                                <h5 className="mb-2 mt-4">Side Chain Blouses</h5>
                                <p className="mb-5">
                                  With stylish side chains expertly integrated into the design, our side chain blouses add a modern and sophisticated touch to traditional attire, creating a statement look that captivates attention.
                                </p>
                              </div>
                              <div className="col-sm-6 my-sm-6 border-lg-start ps-sm-5">
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/PrincessCutBlouse.png"
                                  alt="Pricess Cut Blouse Designs in Noida"
                                />
                                <h5 className="mb-2 mt-4">Princess Cut Blouses</h5>
                                <p className="mb-5">
                                  Designed to accentuate your curves and highlight your femininity, our princess cut blouses are crafted with precision stitching and attention to detail, ensuring a flattering and regal appearance.
                                </p>
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/CholiCutBlouse.png"
                                  alt="Choli Cut Blouse Designs"
                                />
                                <h5 className="mb-2 mt-4">Choli Cut Blouses</h5>
                                <p className="mb-5">
                                  Inspired by traditional choli designs, our choli cut blouses are intricately stitched with vibrant patterns and rich colors, making them the perfect choice for festive celebrations and cultural events.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className="py-3">
                      <div className="container-fluid">
                        <div className="row justify-content-center">
                          <div className="col-lg-10 col-xl-8">
                            <div
                              className="swiper-container theme-slider gallerySwiper"
                              data-swiper='{"autoplay":{"delay":3000},"loop":true,"slideToClickedSlide":true,"effect":"fade","pagination":{"type":"bullets","clickable":true}}'
                            >
                              <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                  <div className="card">
                                    {" "}
                                    <img
                                      className="img-fluid"
                                      src="assets/img/gallery/AllDesignerDresses.png"
                                      alt="Designer Dresses in Noida"
                                    />
                                    <div className="card-body px-2">
                                      <h5 className="my-3 text-center">
                                        Pre Draped Sarees & Party Wear Dresses
                                      </h5>
                                      <p className="mb-0">
                                        At Designer Karigar, we love making sarees and party wear dresses that make you feel amazing at special occasions.
                                      </p><p className="mb-0">
                                        Our sarees are stitched with care, making sure they fit you perfectly and feel comfortable. We have lots of different styles to choose from, so you can find the perfect one for you.
                                      </p><p className="mb-0">
                                        And when it comes to party wear dresses, we've got you covered! Our dresses are stitched with attention to detail, so you'll look stunning wherever you go.
                                      </p><p className="mb-0">
                                        So if you're looking for beautiful sarees or party wear dresses that are stitched just for you, come and see us at Designer Karigar. We can't wait to help you find the perfect outfit!.{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide">
                                  <div className="card">
                                    <img
                                      className="img-fluid"
                                      src="assets/img/gallery/Stitched-Saree.png"
                                      alt="Drape Sarees in Noida"
                                    />
                                    <div className="card-body px-2">
                                      <h5 className="my-3 text-center">
                                        Pre Draped Sarees
                                      </h5>
                                      <p className="mb-0">
                                        Introducing Pre Draped Sarees by Designer Karigar – the hassle-free way to elegance! Our prestitched sarees are designed for effortless wear, perfect for those who are new to sarees or simply want convenience. Just slip into our pre draped sarees like a skirt, and with the help of a button in the palla, you're ready to go in just 2 minutes! Say goodbye to the complexities of traditional draping and hello to instant sophistication with Designer Karigar's Pre Draped Sarees.{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide">
                                  <div className="card">
                                    <img
                                      className="img-fluid"
                                      src="assets/img/gallery/DesignerLehenga.png"
                                      alt="Designer Lehenga Stitching in Noida"
                                    />
                                    <div className="card-body px-2">
                                      <h5 className="my-3 text-center">
                                        Designer Lehenga
                                      </h5>
                                      <p className="mb-5">
                                        At Designer Karigar, we specialize in stitching exquisite Lehengas that make you stand out on your special day. Whether it's your wedding, your friend's wedding, or your sister's wedding, we're here to create a unique style statement just for you.
                                      </p><p className="mb-5">
                                        Our expert designers work closely with you to bring your vision to life. Whether you want to emulate your favorite celebrity's style or have your own unique design in mind, we'll help you finalize the perfect design that complements your fabric preferences and body type.
                                      </p><p className="mb-5">
                                        With Designer Karigar, you can trust us to craft a Lehenga that not only reflects your individuality but also makes you feel confident and beautiful on your big day. Let us turn your dream Lehenga into reality and make your wedding or special occasion truly unforgettable.{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="swiper-pagination"> </div>
                            </div>
                            <div className="row pt-5">
                              <div className="col-sm-6 my-sm-6 border-lg-end pe-sm-5">
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/WesternGown.png"
                                  alt="Western Gowns Stitching Services"
                                />
                                <h5 className="mb-2 mt-4">Stitching Western Wear Dresses</h5>
                                <p className="mb-5">
                                  At Designer Karigar, we're not just about traditional attire – we also specialize in crafting stunning western wear dresses that elevate your style quotient. Whether it's a casual outing, a formal event, or a night on the town, we have the perfect dress for every occasion.
                                </p><p className="mb-5">
                                  Our expert designers are here to help you create a unique style statement that suits your personality and preferences. Whether you're inspired by the latest fashion trends or have your own vision in mind, we'll work closely with you to bring your ideas to life.
                                </p><p className="mb-5">
                                  From chic cocktail dresses to elegant evening gowns, our collection offers a wide range of options to choose from. And with our attention to detail and commitment to quality, you can trust that each dress is crafted with care and precision.
                                </p><p className="mb-5">
                                  So whether you're looking for the perfect dress for a special occasion or simply want to add some flair to your wardrobe, Designer Karigar has you covered. Let us help you find the perfect western wear dress that makes you look and feel amazing, wherever life takes you.{" "}
                                </p>
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/KidsWearDressesStitching.png"
                                  alt="Kids Wear Dresses Stitching"
                                />
                                <h5 className="mb-2 mt-4">Kids Wear Dresses specially for your baby Girls</h5>
                                <p className="mb-5">
                                  At Designer Karigar, we believe that kids deserve to look their best too! That's why we offer expert stitching services for kids' wear dresses that are as stylish as they are comfortable.
                                </p><p className="mb-5">
                                  Whether it's for a birthday party, a family gathering, or just everyday wear, our skilled designers are here to create adorable outfits that your little ones will love. From cute dresses to stylish shirts and trousers, we have a wide range of options to choose from.
                                </p><p className="mb-5">
                                  Our team understands that kids come in all shapes and sizes, which is why we offer custom stitching services to ensure the perfect fit every time. We'll work closely with you to understand your child's preferences and create a dress that reflects their unique personality.
                                </p><p className="mb-5">
                                  With Designer Karigar, you can trust that your kids will always be dressed to impress. Let us help you create beautiful and comfortable outfits that your little ones will cherish for years to come.{" "}
                                </p>
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/BirthdayDress.png"
                                  alt="Birthday Girl Dress for Babies"
                                />
                                <h5 className="mb-2 mt-4">Birthday Dresses</h5>
                                <p className="mb-5">
                                  At Designer Karigar, we specialize in stitching birthday dresses that make your beautiful baby girls feel like princesses on their special day. Whether they're turning one or ten, we're here to create a dress that's as unique and magical as they are.
                                </p><p className="mb-5">
                                  From adorable tutu dresses to elegant ball gowns, our skilled designers have a wide range of options to choose from. We understand that every girl is different, which is why we offer custom stitching services to ensure that the dress fits perfectly and reflects her individual style.
                                </p><p className="mb-5">
                                  Our team pays close attention to detail, ensuring that every stitch is made with care and precision. Whether your little one prefers bold colors, delicate lace, or sparkling embellishments, we'll work closely with you to create a dress that exceeds your expectations.
                                </p><p className="mb-5">
                                  With Designer Karigar, you can trust that your baby girl will look and feel like a princess on her birthday. Let us help you create a birthday dress that she'll cherish for years to come, making her special day even more memorable.{" "}
                                </p>
                              </div>
                              <div className="col-sm-6 my-sm-6 border-lg-start ps-sm-5">
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/safarisuits.png"
                                  alt="Safari Suits for Mens"
                                />
                                <h5 className="mb-2 mt-4">Safari Suits for Mens</h5>
                                <p className="mb-5">
                                  At Designer Karigar, we understand that every man deserves to look his best, no matter the occasion. That's why we offer expert stitching services for safari suits that exude style and sophistication.
                                </p><p className="mb-5">
                                  Whether you're attending a formal event, a business meeting, or a casual outing, our skilled designers are here to create safari suits that make a statement. From classic designs to modern interpretations, we have a wide range of options to choose from.
                                </p><p className="mb-5">
                                  Our team pays meticulous attention to detail, ensuring that every safari suit is tailored to perfection. Whether you prefer a slim fit or a more relaxed silhouette, we'll work closely with you to create a suit that fits your body type and personal style.
                                </p><p className="mb-5">
                                  With Designer Karigar, you can trust that your safari suit will be made with the finest materials and craftsmanship. Let us help you look and feel confident and stylish in a safari suit that's as unique as you are.{" "}
                                </p>
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/IndoWesternDresses.png"
                                  alt="Indo Western Wear Stitching"
                                />
                                <h5 className="mb-2 mt-4">Indo Western Dresses</h5>
                                <p className="mb-5">
                                  At Designer Karigar, we specialize in stitching Indo Western dresses that blend the elegance of traditional Indian attire with the modern flair of Western fashion.
                                </p><p className="mb-5">
                                  Whether you're attending a wedding, a festive celebration, or a special event, our skilled designers are here to create the perfect Indo Western dress for you. From fusion saree gowns to contemporary lehenga suits, we offer a wide range of options to suit your style and occasion.
                                </p><p className="mb-5">
                                  Our team pays close attention to detail, ensuring that every stitch is made with precision and care. We work closely with you to understand your preferences and create a dress that reflects your unique personality and taste.
                                </p><p className="mb-5">
                                  With Designer Karigar, you can trust that your Indo Western dress will be tailored to perfection, using the finest materials and craftsmanship. Let us help you make a style statement that's sure to turn heads at any event.{" "}
                                </p>
                                <img
                                  className="img-fluid"
                                  src="assets/img/gallery/MotherDaughterDuoDresses.png"
                                  alt="Mother Daughter Duo Dresses"
                                />
                                <h5 className="mb-2 mt-4">Mother Daughter Duo Dresses</h5>
                                <p className="mb-5">
                                  At Designer Karigar, we cherish the special bond between mothers and daughters by offering expert stitching services for matching mother-daughter duo dresses.</p><p className="mb-5">
                                  Whether you're attending a family gathering, a special occasion, or just enjoying a day out together, our skilled designers are here to create dresses that celebrate your unique connection. From coordinating colors to complementary designs, we'll work closely with you to craft outfits that reflect your love and harmony.
                                </p><p className="mb-5">
                                  Our team pays meticulous attention to detail, ensuring that both the mother and daughter dresses are tailored to perfection. Whether you prefer classic silhouettes or trendy styles, we have a wide range of options to choose from to suit your tastes.
                                </p><p className="mb-5">
                                  With Designer Karigar, you can trust that your mother-daughter duo dresses will be made with the finest materials and craftsmanship. Let us help you create unforgettable memories with matching outfits that symbolize your special bond.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="col-12">
                    <div className="row justify-content-center pt-3d">
                      <div className="col-lg-8 col-xl-7 mb-n5 mb-md-n8 mb-lg-n6 mb-xl-n9 mt-8">
                        <div className="card bg-backdrop">
                          <div className="card-body p-2 px-lg-2 px-xl-5">
                            <div className="py-5 text-center">
                              <h2 className="fs-1 fs-xxl-2 fw-bolder">Book</h2>
                              <p className="mb-4 text-900">
                                Your Stitching Appointment Now!!
                              </p>
                              <a
                                className="btn hover-top btn-boots-warning"
                                href="/contact-us"
                              >
                                BOOK
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-10">
                        <img
                          className="w-100"
                          src="assets/img/cta/cta-gallery.png"
                          alt="Stitching Services in Noida"
                          style={{ borderRadius: "1rem" }}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center my-3">
                      <div className="col-12 col-lg-10">
                        <div className="card bg-soft-cyan-2">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-lg-6 text-center text-lg-start">
                                <p className="fs--1 my-2 fw-bold text-gradient-cyan-soft-2">
                                  All rights Reserved &copy; AGA Designer Karigar Pvt Ltd., 2024
                                </p>
                              </div>
                              <div className="col-lg-6 d-lg-flex align-self-center justify-content-end text-center">
                                <p className="fs--1 mb-0 text-gradient-cyan-soft-2">
                                  Made with{" "}
                                  <span className="fas fa-heart mx-1 text-success"></span>
                                  by
                                  <a href="https://www.designerkarigar.com/">
                                    {" "}
                                    Designer Karigar &copy;
                                  </a>
                                </p>
                                <img
                                  src="/assets/img/logos/diamond.png"
                                  alt="Designer Karigar Book Your Order logo"
                                  className="very-small-logo"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3 position-absolute position-lg-relative ps-lg-0">
                <div
                  className="sticky-top py-4 sticky-area"
                  data-sidebar-close="sidebar-close"
                >
                  <a href="/"><div className="btn-close-boots-container times">
                    <div className="btn-close-boots"></div>
                  </div></a>
                  <div
                    className="bg-holder sidebar-rounded"
                    style={{
                      backgroundImage: "url(assets/img/sidebars/gallery.png)",
                    }}
                  ></div>

                  <div className="position-relative d-lg-flex flex-column justify-content-end align-items-end h-100 px-lg-4 px-xxl-5">
                    <h1 className="text-white text-vertical px-5 px-lg-0 opacity-50 fs-xl-3 fs-xxl-4">
                      Boutique
                    </h1>
                    <img
                      className="d-none d-lg-block line-icons mt-5"
                      src="assets/img/lineicons/Boutique.png"
                      alt="Boutique in Noida"
                    />
                    <hr className="my-4 w-100 d-none d-lg-block opacity-25" />
                    <div
                      className="flex-between-center d-none d-lg-flex w-100 opacity-75"
                      data-sidebar-link="page-link"
                    >
                      <Link
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        to="/blog"
                      >
                        {" "}
                        <i className="fas fa-chevron-left me-lg-2 me-xl-2 me-xxl-3"></i>
                        <span className="text-capitalize">blogs</span>
                      </Link>
                      <Link
                        className="sidebar-nav btn btn-link text-decoration-none px-1"
                        to="/about-us"
                      >
                        <span className="text-capitalize">about</span>
                        <i className="fas fa-chevron-right ms-lg-2 ms-xl-2 ms-xxl-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default GalleryComponent;
